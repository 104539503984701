// REQUIRED (after moving most academy fields from constants.ts to the /academy document in Firestore)
// name: 'Third Eye Global',
// startupRoute: 'play',
// defaultAcademy: 'LGzpWytfchYLtj1ptovZkpUa98m2',

export const academies = {
  // HACK YOUR HUMAN
  hackYourHuman: {
    name: 'Hack Your Human',
    startupRoute: 'play',
    defaultAcademy: 'ugWavqSLcOReqV9KpVcg0Vpz4k53',
    firebaseProject: "hackyourhuman",
  },
  // SAGA
  saga: {
    name: 'SAGA',
    startupRoute: 'play',
    defaultAcademy: 'ugWavqSLcOReqV9KpVcg0Vpz4k53',
    firebaseProject: "saga-tech",
  },
  // SEVEN MOVEMENTS RESEARCH
  sevenMovementsResearch: {
    name: 'Seven Movements Research',
    startupRoute: 'play',
    defaultAcademy: 'MoNWanoNRSPLwwJyTwf2eMhcH4y2',
    firebaseProject: "hackyourhuman",
  },
  // SEVEN MOVEMENTS
  sevenMovements: {
    name: 'Seven Movements',
    startupRoute: 'play',
    // defaultAcademy: '3nkS8sO6v2WpJh4EEiFAz94KxEJ2', // uid on saga-tech
    defaultAcademy: '3RUCfxMVDoSZ7WmRG0p70C8kO5H3', // uid on seven-movements
    // firebaseProject: "hackyourhuman",
    // firebaseProject: "saga-tech",
    firebaseProject: "seven-movements",
  },
  // 3EG
  thirdEyeGlobal: {
    name: 'Third Eye Global',
    startupRoute: 'play',
    defaultAcademy: 'LGzpWytfchYLtj1ptovZkpUa98m2',
    firebaseProject: "saga-tech",
  },
}