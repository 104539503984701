import { defaults } from 'src/constants/constants';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class EnrollService {

  defaults = defaults;

  collection = "users";
  subCollection = "enrollments";
  // limit = defaults.limits.enrolls;

  userId: string;

  private enrollCollection: AngularFirestoreCollection<any>;
  private enrolls: Observable<any[]>;

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    // public db: AngularFirestore,

  ) {
    console.log('Enroll Service instantiated.');
    afAuth.authState.subscribe((auth) => {
      if (auth) { 
        this.userId = auth.uid;
        console.log('afAuth.authState.subscribe User id:', this.userId);
        this.init();
      }
    });
  }


  init(userId?) {
    if (!userId) userId = this.userId;
    // OG (issue, can't see the collection string)
    // this.enrollCollection = this.afs.collection<any>(this.collection).doc(userId).collection(this.subCollection);
    // this.enrollCollection = this.afs.collection<any>(this.collection).doc(userId).collection(this.subCollection);

    // NEW (so we can see the collection string via console log)
    const collectionString = `${this.collection}/${userId}/${this.subCollection}`;
    console.log("Enroll collectionString", collectionString);
    this.enrollCollection = this.afs.collection<any>(collectionString);

    // CONT...
    this.enrolls = this.enrollCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    // , first());
  }

  initActive() {
    const collectionString = 'users-enrollment';
    console.log("Enroll collectionString", collectionString);
    this.enrollCollection = this.afs.collection<any>(collectionString);
    this.enrolls = this.enrollCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    // , first());
  }

  getItems() {
    return this.enrolls;
  }

  getItem(id) {
    return this.enrollCollection.doc<any>(id).valueChanges();
  }

  updateItem(program: any, id: string) {
    return this.enrollCollection.doc(id).update(program);
  }

  addItem(program: any) {
    return this.enrollCollection.add(program);
  }

  removeItem(id) {
    return this.enrollCollection.doc(id).delete();
  }
















  createEnrollmentIntent(userId: string, academyId: string, programId: string, coupon?: any, referral?) {
    if (!academyId || !programId) {
      console.log('%c Missing academyId or programId. Failed to create enrollment intent.', defaults.styles.error, {academyId, programId});
      return;
    }

    // ENROLLMENT REQUEST
    let enrollment: any;
    enrollment = { 
      uid: userId,
      academyId,
      programId,
      version: defaults.version,
    }

    if (coupon) {
      enrollment.coupon = coupon;
    }

    if (referral) {
      enrollment.referral = referral;
    }

    // ENROLLMENT COLLECTION
    const collection = `${this.collection}/${userId}/${this.subCollection}`;
    console.log('%c Set user enrollment intent collection.', defaults.styles.acme4, collection);
    return this.afs
      .collection(collection)
      .add(enrollment);
  }














  hasFinishedOnboardingSuccessfully(activeEnrollment) {
    if (!activeEnrollment?.version && !activeEnrollment?.onboardingLastCompletedAtLocalString) {
      console.log('%c Unable to check if onboarding completed for the outdated versions of Active Enrollment (before 0.6.4-a-6). Assuming user onboarding is complete.', defaults.styles.heal, activeEnrollment);
      return true;
    }
    if (activeEnrollment.onboardingLastCompletedAt) {
      console.log('%c Onboarding completed', defaults.styles.success, activeEnrollment);
      return true;
    }
    console.log('%c Onboarding NOT completed', defaults.styles.heal, activeEnrollment);
    return false;
  }

  hasFinishedOnboardingQuestionsSuccessfully(activeEnrollment) {
    if (!activeEnrollment?.version && !activeEnrollment?.onboardingLastCompletedAtLocalString) {
      console.log('%c Unable to check if onboarding completed for the outdated versions of Active Enrollment (before 0.6.4-a-6). Assuming user onboarding is complete.', defaults.styles.heal, activeEnrollment);
      return true;
    }
    if (activeEnrollment.onboardingQuestionsLastCompletedAt) {
      console.log('%c Onboarding Questions completed', defaults.styles.success, activeEnrollment);
      return true;
    }
    console.log('%c Onboarding Questions NOT completed', defaults.styles.heal, activeEnrollment);
    return false;

  }









  getLatestItems(userId?, options?): Observable<any> {
    if (!userId) userId = this.userId;
    const collectionString = `${this.collection}/${userId}/${this.subCollection}`;
    let order = options?.order ?? "createdAt";
    let orderDirection = options?.orderDirection ?? "desc";
    let livemode = options?.livemode ?? undefined;
    let limit = options?.limit ?? 20;
    let queryFn: QueryFn = ref => {
      let query = ref.orderBy(order, orderDirection);
      if (livemode) {
        query = query.where('livemode', '==', livemode);
      }
      return query.limit(limit);
    };
    const documents = this.afs.collection<any>(collectionString, queryFn)
    .snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data } as any;
        });
      })
    // );
    , first());
    return documents;
  }
}
