// AUTOMATICALLY SET FIREBASE SOURCES
import { defaults } from "src/constants/constants";

export let firebaseSources: any;
console.log ("%c SET FIREBASE SOURCES based on firebaseProject.", defaults.styles.acme, defaults.platform.firebaseProject);

//
// HACKYOURHUMAN (primary academy: sevenmovementsresearch)
if (defaults.platform.firebaseProject === "hackyourhuman") {
  console.log ("%c Firebase sources set to: HACK-YOUR-HUMAN", defaults.styles.coder, defaults.platform.firebaseProject);
  firebaseSources = {
    apiKey: "AIzaSyDauzio_jOoykvHd-9PIBkrINVQnZostGY",
    authDomain: "hackyourhuman.firebaseapp.com",
    databaseURL: "https://hackyourhuman.firebaseio.com",
    projectId: "hackyourhuman",
    storageBucket: "hackyourhuman.appspot.com",
    messagingSenderId: "362273416627",
    appId: "1:362273416627:web:bd89abfbbbc0b4e4df97c6",
    measurementId: "G-TJLKG7WM50"
  }
}

//
// SAGA TECH (primary academy: third-eye-global)
else if (defaults.platform.firebaseProject === "saga-tech") {
  console.log ("%c Firebase sources set to: SAGA-TECH", defaults.styles.coder, defaults.platform.firebaseProject);
  firebaseSources = {
    apiKey: "AIzaSyA0vJs2Si03wW-A10J6N374dZpBclv69RA",
    authDomain: "saga-tech.firebaseapp.com",
    databaseURL: "https://saga-tech-default-rtdb.firebaseio.com",
    projectId: "saga-tech",
    storageBucket: "saga-tech.appspot.com",
    messagingSenderId: "889426806741",
    appId: "1:889426806741:web:c75802cae9e8687cf41f5c",
    measurementId: "G-62H902W664"
  }
}

//
// SAGA LIFE
else if (defaults.platform.firebaseProject === "sagalifeapp") {
  console.log ("%c Firebase sources set to: sagalifeapp", defaults.styles.coder, defaults.platform.firebaseProject);
  firebaseSources = {
    apiKey: "AIzaSyC-b8YcqpJBRUIFhdVSZmukvNokKgxfir0",
    authDomain: "sagalifeapp.firebaseapp.com",
    projectId: "sagalifeapp",
    storageBucket: "sagalifeapp.appspot.com",
    messagingSenderId: "740758588701",
    appId: "1:740758588701:web:8bb9fe6d05f3bb4ebba5f4",
    measurementId: "G-98Y4ZHN20G"
  }
}

//
// SEVEN MOVEMENTS
else if (defaults.platform.firebaseProject === "seven-movements") {
  console.log ("%c Firebase sources set to: SEVEN-MOVEMENTS", defaults.styles.coder, defaults.platform.firebaseProject);
  firebaseSources = {
    apiKey: "AIzaSyApNBbrsOp335qu8IAiHvRafmLlKe3-YQo",
    authDomain: "seven-movements.firebaseapp.com",
    databaseURL: "https://seven-movements.firebaseio.com",
    projectId: "seven-movements",
    storageBucket: "seven-movements.appspot.com",
    messagingSenderId: "88727172379",
    appId: "1:88727172379:web:8dbcfbea48072565",
    measurementId: "G-DKK9074PZR"
  }
}

//
// MOST CONNECTED
else if (defaults.platform.firebaseProject === "most-connected") {
  console.log ("%c Firebase sources set to: MOST-CONNECTED", defaults.styles.coder, defaults.platform.firebaseProject);
  firebaseSources = {
    apiKey: "AIzaSyAq-fA4T3scN2K2UziGXK9LggUId1EWvS0",
    authDomain: "most-connected.firebaseapp.com",
    databaseURL: "https://most-connected.firebaseio.com", // Added manually, was not part of the Firebase config
    projectId: "most-connected",
    storageBucket: "most-connected.appspot.com",
    messagingSenderId: "982742630354",
    appId: "1:982742630354:web:73c141b9869daccf2cd20b",
    measurementId: "G-CSM3PDMS9T"
  }
}

//
// ELK AND OWL
else if (defaults.platform.firebaseProject === "elk-and-owl") {
  console.log ("%c Firebase sources set to: MOST-CONNECTED", defaults.styles.coder, defaults.platform.firebaseProject);
  firebaseSources = {
    apiKey: "AIzaSyCMrNaUQ7UVlmiIh6MNhkzPwT-jyrVF6FM",
    authDomain: "elk-and-owl.firebaseapp.com",
    projectId: "elk-and-owl",
    storageBucket: "elk-and-owl.appspot.com",
    messagingSenderId: "42481009828",
    appId: "1:42481009828:web:f0fb52118c2a2fe39f1231",
    measurementId: "G-X2WC5VNHQY"
  }
} 

// ERROR - PROJECT NOT FOUND
else {
  console.log("%c FATAL ERROR! FIREBASE PROJECT NOT FOUND: " + defaults.platform.firebaseProject, defaults.styles.acmeError);
}