// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

import { firebaseSources } from "src/constants/firebaseProjects";


// The list of file replacements can be found in `angular.json`.
export let environment: any = {
  //
  // PRESETS
  production: true, // CONFIG: Make sure we set this to true before deploying to release channels
  firebase: firebaseSources,
  hasPayments: false, // CONFIG: Make sure this is set to false when building native app
  autosave: true,

  //
  // DEVELOPMENT TOOLS
  verboseLogs: false,

  //
  // SET DURING EXECUTION
  platforms: null,
  isApp: null,
  isDesktop: null,
  isCordova: null,
  width: null,
  height: null,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
