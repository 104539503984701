// tslint:disable: max-line-length
import { catchError, first, map } from 'rxjs/operators';
import { defaults } from 'src/constants/constants';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, DocumentReference, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { HelpersService } from './Helpers.service';

export interface Testimonial {
  content: string;
  author: string;
  image: string;
}
// Member model interface (adjust fields as per your Firestore document structure)
interface Member {
  // ... your member properties here
  waiveFees?:boolean;
}
export interface Academy {
  id?: string;
  uid?: string;

  // META
  updatedAt?: number;
  createdAt?: number; // server timestamp

  // BRANDING
  logo?: string;
  bgColor?: string;
  colorPrimary?: string;

  // SITE NAME
  name: string;
  address: string;
  slug?: string;

  isPublic?: boolean;
  enrollViaEmailOnly?: boolean;
  allowMultiplePrograms?: boolean;
  defaultProgram?: string;
  canMessageOtherUsers?: boolean

  // SITE HEADER (OLD)
  // siteHeaderText: string;
  // siteSubHeaderText: string;
  // siteHeader?: string;

  featuredIn?: string; // Image URL

  // NAVIGATION
  navigation?: {};

  // HERO BANNER
  heroBanner?: {};

  // FEATURES
  features?: {};

  // ANALYTICS
  reads?: number;
  totalEarned?: number; // Total earned from program sales from clients
  totalSpent?: number;  // Total spend on HYH services

  testimonial?: Testimonial;

  // COACH
  coachTitle?: string;
  coachSummary?: string;
  coachQuote?: string;
  youTubeVideoId?: string;

  // PROGRAMS
  programHeadline?: string;

  // BANNER
  bannerTitle?: string;
  bannerSubTitle?: string;
  bannerSubSubTitle?: string;
  bannerImage?: string;
  bannerCTA?: string;

  // CONTACT
  coachName?: string;
  coachAvatar?: string;
  contactTitle?: string;
  contactParagraph?: string;
  email?: string;
  phone?: string;

  // SOCIAL
  instagram?: string;
  twitterHandle?: string;
  facebook?: string;

  // CLOSING STATEMENT
  closingStatement?: string;
  closingImage?: string;

  // CLOSING STATEMENT BANNER
  closingBanner?: any;

  // COUPONS
  couponsEnabled: true,

  // PREVIEW (demo mode)
  noSubscriptionLimitedViewEnabled?: boolean;
  numberOfHabitsInDemo?: number;

  // PLAYER BRANDING
  playerColorBg?: string;
  playerColorBgDarkMode?: string;
  playerColorPrimary?: string;
  playerColorPrimaryContrast?: string;
  playerColorLight?: string;
  playerColorMedium?: string;
  playerColorDark?: string;

  playerColorSuccess?: string;
  playerColorWarn?: string;
  playerColorDanger?: string;

  // BUTTONS
  buttonFill?: string;

  onboardingSlidesLogo?: string;
  onboardingSlidesLogoRight?: string;

  playerMenuSelectProgram?: boolean;
  playerMenuPreviewWeekAhead?: boolean;
  playerMenuActivityFeed?: boolean;
  playerMenuManageHabits?: boolean;
  playerMenuOnboarding?: boolean;
  playerMenuQuestions?: boolean;
  playerMenuReport?: boolean;
  playerMenuNotes?: boolean;
  playerMenuSetYourGoal?: boolean;
  playerMenuDiscussions?: boolean;
  
  // COMPONENTS
  showReadinessScore?: boolean;
  showProgressBar?: boolean;
  showReportCard?: boolean; // hideReportCard is depreciated
  showConsecutiveDays?: boolean;
  showMessagingIcon?: boolean;

  // REPORT
  reportActivityTypeReport?: boolean;
  reportShowExperiencePoints?: boolean;
  reportShowActivityCompliance?: boolean;
  reportShowBiometricCompliance?: boolean;
  reportShowActivityHistory?: boolean;
  reportShowBiometricHistory?: boolean;
  reportShowWorkoutHistory?: boolean;
  reportShowNotes?: boolean;
  
  // REPORT CARD
  reportCardTitle?: string;
  reportCardShowCompletedCount?: boolean;
  reportCardShowCompletedOutOfTotal?: boolean;
  reportCardShowPercentageCircle?: boolean;

  // TEMPLATES
  landingTemplate?: string;
  programPreviewTemplate?: string;

  // PROGRAM SELECT
  showAcademyNameInMasthead?: boolean;
  hideProgramTileInMastHead?: boolean;

  // ONBOARDING
  faqs?: any[];
  onboardingSlides?: any[];
  eventsBanner?: {};
  textAlign?: 'left';
  questions?: {};
  equipment?: {}[];
  goals?: {}[];
  
  // ALERT (onboarding not completed)
  presentAlertIfOnboardingNotCompleted?: boolean;
  onboardingNotCompletedAlertName?: string;
  onboardingNotCompletedAlertDescription?: string;
  onboardingNotCompletedAlertColor?: string;
  // TOAST
  presentToastIfOnboardingNotCompleted?: boolean;
  onboardingNotCompletedToastName?: string;
  onboardingNotCompletedToastButton?: string;
  onboardingNotCompletedToastColor?: string;

  
  // ALERT (questions not completed)
  presentAlertIfOnboardingQuestionsNotCompleted?: boolean;
  onboardingQuestionsNotCompletedAlertName?: string;
  onboardingQuestionsNotCompletedAlertDescription?: string;
  onboardingQuestionsNotCompletedAlertColor?: string;
  // TOAST
  presentToastIfOnboardingQuestionsNotCompleted?: boolean;
  onboardingQuestionsNotCompletedToastName?: string;
  onboardingQuestionsNotCompletedToastButton?: string;
  onboardingQuestionsNotCompletedToastColor?: string;

  // SUBSCRIPTION
  academyWideSubscription?: boolean; // subscribing to any pay program, will give Academy wide access
  
  // ALERT (no subscription)
  noSubscriptionShowAlert?: boolean;
  noSubscriptionAlertName?: string;
  noSubscriptionAlertDescription?: string;
  noSubscriptionAlertButton?: string;
  noSubscriptionAlertURL?: string;
  // TOAST (no subscription)
  noSubscriptionToastEnabled?: boolean;
  noSubscriptionToastMessage?: string;

  // EMAIL TEMPLATES
  welcomeEmail?: string;
  enrollmentEmail?: string;
  inactivityEmail?: string;

  // EMAIL ACTIVE SETTING
  sendMemberWelcomeEmail?: boolean;
  sendEnrollmentEmail?: boolean;
  sendInactivityEmail?: boolean;

  // ENROLLMENT BACKGROUND
  enrollmentScreenBackground?: string;
  enrollmentScreenBackgroundPosition?: string;

  programActivitiesShownFirst?: boolean;
  showMemberPersonalizations?: boolean;

  player?: {}; // preparing for upgrade player configuration

  solutions?: {}[];

  defaultIcon?: string;
  personalizationSectionLabels?: string;

  activityVerb?: string;
  activityVerbPlural?: string;

  showFavoritesEvenIfEmpty?: boolean;
  closeParentPopover?: boolean;
}

@Injectable()
export class AcademyService {

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public loadingController: LoadingController,
  ) {
    console.log('%c Authorizing user', defaults.styles.authorizing);
    afAuth.authState.subscribe((auth) => {
      if (auth) {
        this.userId = auth.uid;
        console.log('%c Authorized User id:', defaults.styles.authorized, this.userId);
        this.init();
      } else {
        console.warn('%c User auth failed.', defaults.styles.authorized);
      }
    });
  }

  id: string;
  userId: string;

  public docRef: DocumentReference;

  private academiesCollection: AngularFirestoreCollection<Academy>;
  private academies: Observable<Academy[]>;

  private academy = new BehaviorSubject(null);

  public static getDefaultAcademy(uid): Academy {
    let academy: any;
    academy = {
      id: uid, // should this be set to this?
      uid, // userId ???

      // BRANDING
      logo: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2FSBCO_Final_Logo-02.webp?alt=media&token=1007cf10-09b1-49c8-a634-64a00aae94a7',
      bgColor: '#fff',
      colorPrimary: '#c70005',

      // SITE NAME
      name: '', // Your Site Name
      address: '', // your-site-name
      slug: '',

      // SITE HEADER (OLD??)
      // siteHeader: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2Fsonny_brown_banner.jpg?alt=media&token=9005420b-bf8e-4ed8-849e-f4ab4f797294',
      // siteHeaderText: '',
      // siteSubHeaderText: '',

      featuredIn: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2FfeaturedIn.png?alt=media&token=69cd1628-64fd-4ce2-bcba-887e1dd965c5',

      // NAVIGATION
      // navigation,

      // HERO BANNER
      heroBanner: AcademyService.getDefaultHeroBanner(),

      // FEATURES
      features: AcademyService.getDefaultFeatures(),

      // TESTIMONIAL
      testimonial: AcademyService.getDefaultTestimonial(),

      // ANALYTICS
      reads: 0,
      totalEarned: 0,
      totalSpent: 0,

      // COACH
      coachAvatar: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_avatars%2Fdefault_female.svg?alt=media&token=3076b3e8-69ad-449f-b9e2-cffbcf95aa48',
      coachTitle: 'About Sonny',
      coachSummary: 'Fitness has provided me a lifestyle that I used to dream of while connecting me with like minded people from all over the world. I live and breath health and fitness and my truest passion is helping others reach their personal peak\'s in all elements of life.',
      coachQuote: 'The blueprint is simple; find something you are passionate about and don\'t look back.',
      youTubeVideoId: 'tB1Y6BLutPM',

      // PROGRAMS
      programHeadline: 'Reshape your life from anywhere in the world with online coaching',

      // BANNER
      bannerTitle: 'LIFESTYLE RETREAT',
      bannerSubTitle: 'KOH SAMUI, THAILAND',
      bannerSubSubTitle: 'Fitness. Mindset. Adventure. Retreat.',
      bannerImage: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2FbannerBg.webp?alt=media&token=69107751-76f9-4f63-8d4f-32ba55a0e84a',
      bannerCTA: 'Learn more',

      // CONTACT

      // SOCIAL

      // CLOSING STATEMENT
      closingStatement: 'AUSTRALIA\'S #1 PUBLISHED FITNESS MODEL',
      closingImage: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2FclosingImage.jpg?alt=media&token=057223c5-f46c-452e-b7b7-9b2df5ed2ada',

      // CLOSING STATEMENT BANNER
      closingBanner: this.getDefaultClosingBanner(),

      // COUPONS
      couponsEnabled: false, // as of 0.6.4 coupons not fully implemented (only partially working at only a 100% discount to waive all fees for pay programs, and have to be manually set in firestore)

      // PREVIEW (demo mode)
      noSubscriptionLimitedViewEnabled: true,
      numberOfHabitsInDemo: 3,

      // PLAYER BRANDING
      playerColorBg: '#e7e7e4',
      playerColorBgDarkMode: '##121212',
      playerColorPrimary: '#999', 
      playerColorPrimaryContrast: '#FFF',
      playerColorLight: '#CCC',
      playerColorMedium: '#777',
      playerColorDark: '#222',
      playerColorSuccess: defaults.colors.success,
      playerColorWarn: defaults.colors.warn,
      playerColorDanger: defaults.colors.danger,

      // playerCardLightMode
      // playerCardDarkMode
      // playerBorderRadius

      // BUTTONS
      buttonFill: 'solid', // solid, outline, clear

      // ONBOARDING
      faqs: [],
      onboardingSlides: AcademyService.getOnboardingSlides(),
      eventsBanner: AcademyService.getDefaultEvents(),
      questions: AcademyService.getDefaultQuestions(),
      equipment: AcademyService.getDefaultEquipment(),
      goals: AcademyService.getDefaultGoals(),

      // presentAlertIfOnboardingNotCompleted: true,
      // presentToastIfOnboardingNotCompleted: false,

      // COMPONENTS
      showReadinessScore: true,
      showProgressBar: true,
      showReportCard: true,
      showConsecutiveDays: true,
      showMessagingIcon: true,

      // REPORT
      reportActivityTypeReport: true,
      reportShowExperiencePoints: true,
      reportShowActivityCompliance: true,
      reportShowBiometricCompliance: true,
      reportShowActivityHistory: true,
      reportShowBiometricHistory: true,
      reportShowWorkoutHistory: false,
      reportShowNotes: true,

      // REPORT CARD
      reportCardTitle: "Your Report",

      // TEMPLATES

      // PROGRAM
      showAcademyNameInMasthead: true,
      hideProgramTileInMastHead: false,

      // ALERT (onboarding not completed)
      presentAlertIfOnboardingNotCompleted: true,
      onboardingNotCompletedAlertName: "Complete your onboarding",
      onboardingNotCompletedAlertDescription: "Set when you want to get notified to move and create a more personalized experience.",
      onboardingNotCompletedAlertColor: "primary",
      // TOAST (onboarding not completed)
      presentToastIfOnboardingNotCompleted: false,
      onboardingNotCompletedToastName: "Onboarding not completed",
      onboardingNotCompletedToastButton: "Finish Onboarding",
      onboardingNotCompletedToastColor: "primary",

      // ALERT (questions not completed)
      presentAlertIfOnboardingQuestionsNotCompleted: true,
      onboardingQuestionsNotCompletedAlertName: "Complete your Profile",
      onboardingQuestionsNotCompletedAlertDescription: "To get a more personalized experience, we recommend that you complete your profile.",
      onboardingQuestionsNotCompletedAlertColor: "primary",
      // TOAST (questions not completed)
      presentToastIfOnboardingQuestionsNotCompleted: false,
      onboardingQuestionsNotCompletedToastName: "Get a more personalized experience.",
      onboardingQuestionsNotCompletedToastButton: "Complete Your Profile",
      onboardingQuestionsNotCompletedToastColor: "primary",
      
      // ALERT (no subscription)
      noSubscriptionShowAlert: true,
      noSubscriptionAlertName: "Subscribe for full access",
      noSubscriptionAlertDescription: "Join today and enjoy a personalized experience",
      noSubscriptionAlertButton: "Add Payment",
      noSubscriptionAlertURL: HelpersService.getDomain() + "/complete-payment",

      // TOAST (no subscription)
      noSubscriptionToastEnabled: true,
      noSubscriptionToastMessage: "Demo Only",


      // EMAIL TEMPLATES
      welcomeEmail: AcademyService.getWelcomeEmailTemplate(),
      enrollmentEmail: AcademyService.getEnrollmentEmailTemplate(),
      inactivityEmail: AcademyService.getInactivityReminderEmailTemplate(),

      // EMAIL ACTIVE SETTING
      sendMemberWelcomeEmail: true,
      sendEnrollmentEmail: true,
      sendInactivityEmail: true,

      // ENROLLMENT BACKGROUND
      // enrollmentScreenBackground: defaults.images.night,
      enrollmentScreenBackgroundPosition: "center",

      // PLAYER
      player: {
        startupRoute: "play",
        registerRoute: "register",
        enrollRoute: "programs",
        showRegisterButton: true,
      }, // preparing for upgrade player configuration

      activityVerb: "Activity",
      activityVerbPlural: "Activities",

    };

    // RETURN
    return academy;
  }

  public static getOnboardingSlides() {
    return [
      {
        name: 'Welcome to the HYH family',
        description: 'Learn how to be a best version of yourself. Here are three arias of your life where you should thrive',
        image: '/assets/illustrations/humaaans/standing-18.svg',
        backgroundImage: '',
        textColor: '#000000',
        backgroundOverlayColor: '',
        // backgroundOverlayOpacity: '0.7',
      },
      {
        name: 'Nutrition',
        description: 'Learn about super foods and how to add them to your diet',
        image: '/assets/illustrations/humaaans/standing-18.svg',
        backgroundImage: '',
        textColor: '#000000',
        backgroundOverlayColor: '',
        // backgroundOverlayOpacity: '0.7',
      },
      {
        name: 'Movement',
        description: 'Enjoy our easy to use exercise and workout library',
        image: '/assets/illustrations/humaaans/standing-18.svg',
        backgroundImage: '',
        textColor: '#000000',
        backgroundOverlayColor: '',
        // backgroundOverlayOpacity: '0.7',
      },
      {
        name: 'Mindfulness',
        description: 'Quiet your mind and learn how to pay attention only on what\'s really important',
        image: '/assets/illustrations/humaaans/standing-18.svg',
        backgroundImage: '',
        textColor: '#000000',
        backgroundOverlayColor: '',
        // backgroundOverlayOpacity: '0.7',
      },
    ];
    // We are so excited to help you on that journey.
  }

  public static getWelcomeEmailTemplate() {
    return `Hello and welcome to {{academy}}.

Break up sitting, have a movement break to improve your health.  So set your notifications and let's get moving.

We are so happy you are here as we roll out our new platform.  This is our BETA version of the platform and you will see new features pop up from time to time.  Always feel free to provide feedback as we are building this for you!

Some best practices:

  1.  Be sure to set your notifications when you first sign up.
  2.  Try to move at least 3-5 times each day
  3.  Bookmark our page for easy LOGIN if you miss your notifications
  4.  make sure notifications are turned on for your browser.

{{playerLink}}

Thank you so much for joining us here to help make the world a healthier place.

Kindly,
{{coach}}`
  }

  public static getEnrollmentEmailTemplate() {
    return `You've been Enrolled into {{program}} program.

Thank you so much for joining us here to help make the world a healthier place.

{{playerLink}}

Kindly,
{{coach}}`
  }

  public static getInactivityReminderEmailTemplate() {
    return `Hello {{student}}!.

It's been {{lastActive}} since you've taken care of your human.

So come back for some micro-dosing movement for your health.

{{playerLink}}

You'll be glad you did it.

See you soon,
{{coach}} team`
  }

  public static getDefaultEquipment() {
    return [
      {
        name: 'Dumbbell',
        slug: 'dumbbell',
        image: '/assets/equipment/stairs.svg',
        enabled: false,
        description: 'Burn fat & get lean',
      },
      {
        name: 'Leber Fitness',
        slug: 'leber-fitness',
        image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/userMedia%2F3nkS8sO6v2WpJh4EEiFAz94KxEJ2%2F1640066489649_Lebert%20Equalizer.jpeg?alt=media&token=96f7927a-4d4d-477c-a1a1-1faca004fd4c',
        enabled: false,
        description: 'Burn fat & get lean',
      },
      {
        name: 'Hyperwear',
        slug: 'hyperwear',
        image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/userMedia%2F3nkS8sO6v2WpJh4EEiFAz94KxEJ2%2F1640140104791_Hyperwear%20Sandbell%20Pro.jpeg?alt=media&token=d4d85ddb-ac25-4419-b10e-99128e06f777',
        enabled: false,
        description: 'Burn fat & get lean',
      },
      {
        name: 'Stairs',
        slug: 'stairs',
        image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/freakyStorage%2F1600062126017_stairs.jpeg?alt=media&token=9b1ebe9c-f985-40d9-b29a-ab58ee96dfd1',
        enabled: false,
        description: 'Full Body Workout fitness dip stand',
      },
      {
        name: 'Steps',
        slug: 'steps',
        image: '/assets/equipment/steps.svg',
        enabled: false,
        description: 'Tone up & feel healthy with this versatile tool',
      },
      {
        name: 'Equipment Free',
        slug: 'equipment-free',
        image: '/assets/equipment/stairs.svg',
        enabled: true,
        description: 'Body weight exercises you can do anywhere',
      },
    ];
  }

  public static getDefaultGoals() {
    return [
      {
        name: 'Loose weight',
        description: 'Burn fat & get lean',
      },
      {
        name: 'Get fitter',
        description: 'Tone up & feel healthy',
      },
      {
        name: 'Build muscle',
        description: 'Build mass & strength',
      },
    ];
  }

  public static getDefaultSpecialists() {
    return [
      {
        name: 'Slava Savage',
        description: `Slava is the Director of the Portal and also works as a performance coach for Portal Global.
        She is a licensed therapist specializing in neuroscience. Slava is a Kundalini Yoga and meditation teacher. She has been teaching and practicing meditation for over 16 years.`,
        image: '',
        linkURL: '',
        userId: '',
      },
      {
        name: 'Manly P. Hall',
        description: 'Build mass & strength',
        image: '',
        linkURL: '',
        userId: '',
      },
    ];
  }

  public static getDefaultNotifications() {
    return [
        {
            name: 'Rise and Shine',
            description: 'Start your day right',
            message: 'Follow this link to your movement break: {{playerLink}}',
            hour: 7,
            minute: 0,
            enabled: true,
        },
        {
            name: 'Midday Stretch',
            description: 'Get your body moving',
            message: 'Follow this link to your movement break: {{playerLink}}',
            hour: 9, 
            minute: 30,
            enabled: true,
        },
        {
            name: 'Afternoon Rejuvenation',
            description: 'Rejuvenate your self with some afternoon movement',
            message: 'Follow this link to your movement break: {{playerLink}}',
            hour: 12,
            minute: 0,
            enabled: true,
        },
        {
            name: 'Evening Jolt',
            description: 'Jolt your evening',
            message: 'Follow this link to your movement break: {{playerLink}}',
            hour: 19,
            minute: 0,
            enabled: true,
        },
        {
            name: 'Sleep Better Movements',
            description: 'Prepare for a good nights sleep',
            message: 'Follow this link to your movement break: {{playerLink}}',
            hour: 21,
            minute: 0,
            enabled: true,
        },
    ];
}

/*
ChatGP suggested notifications

BROTHER:
Unlock your full squat potential and say goodbye to back pain by adding mobility exercises to your routine - your body will thank you!

FATHER:
Experience the freedom and ease of movement you once had by incorporating mobility work into your day - your body will feel stronger and more agile!

BROTHER (shorter):
Improve your squat and alleviate back pain with mobility exercises - your body will thank you for the extra care.

FATHER (shorter):
Enhance your movement and feel stronger with mobility work - your body deserves it.

BROTHER (punchier):
Don't let back pain hold you back - power up your squat with mobility exercises and feel unstoppable!

FATHER (punchier):
Unleash your body's full potential and take on the world with confidence - mobility work can help you get there!

ACTION:
Don't forget to set aside just 10 minutes each day for mobility work.

*/

  public static getDefaultQuestions() {
    return {
      goal: {
        enabled: true,
        question: 'What\'s your main goal?',
      },
      gender: {
        enabled: true,
        question: 'What\'s your gender?',
      },
      dateOfBirth: {
        enabled: false,
        question: 'What is your date of birth?',
      },
      age: {
        enabled: true,
        question: 'How old are you?',
      },
      height: {
        enabled: true,
        question: 'How tall are you?',
      },
      weight: {
        enabled: true,
        question: 'How Much Do You Weight',
      },
      equipment: {
        enabled: true,
        question: 'Select available equipment',
        description: 'What equipment do you have?',
      },
      daysOfWeek: {
        enabled: true,
        title: 'Days of Week',
        question: 'What days do you want to move?',
        description: 'Set the days you want to get notified to get your daily doses in!',
      },
      notifications: {
        enabled: true,
        question: 'How many daily doses?',
        description: 'Set reminders to get your daily doses in!',
      },
      categoriesOfFocus: {
        enabled: false,
        question: 'Select a category of focus',
        description: 'What is something you want to focus on?',
      },
      solution: {
        enabled: false,
        question: 'Select a solution',
        description: 'What is the most common complaint you might have?',
      },
      activity: {
        enabled: false,
        question: 'Select an activity',
        description: 'What do you play most often?',
      },
      program: {
        enabled: false,
        question: 'Select a program',
        description: 'What is the most interesting program to you?',
      },
      specialist: {
        enabled: false,
        question: 'Select a specialist',
        description: 'What specialist would you like to be in touch with?',
      },
      finished: {
        enabled: true,
        question: 'All set!',
        description: 'We are taking in your information and creating your personalized program.',
      },
    };
  }

  





  public static getDefaultNavigation() {
    return {
      logo: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2Fsonny_brown_banner_no_text.jpg?alt=media&token=9fea4cdb-3f82-4063-b86a-88a7e5fa13a9',
      items: [
        {
          title: 'Programs',
          url: '/programs/##academyId##',
        },
        {
          title: '',
          url: '',
        },
        {
          title: '',
          url: '',
        },
        {
          title: 'Log in',
          url: '/login',
        },
      ]
    };
  }

  public static getDefaultHeroBanner() {
    return {
      background: {
        image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2Fsonny_brown_banner_no_text.jpg?alt=media&token=9fea4cdb-3f82-4063-b86a-88a7e5fa13a9',
        size: 'cover',
        position: 'center',
        repeat: 'no-repeat',
        textColor: '#000',
        textShadow: 'none',
        overlayColor: '#000',
        overlayOpacity: 0,
      },
      content: {
        title: 'Welcome to the Academy',
        subtitle: 'Get ready to upgrade your human',
        subsubtitle: '',
      },
      cta: [
        {
          name: 'Get Coaching',
          url: '',
          fill: 'solid'
        },
        {
          name: 'Lifestyle Retreats',
          url: '',
          fill: 'outline'
        },
      ]
    };
  }

  public static getDefaultFeatures() {
    return {
      background: {
        image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2Fsonny_brown_banner_no_text.jpg?alt=media&token=9fea4cdb-3f82-4063-b86a-88a7e5fa13a9',
        size: 'cover',
        position: 'center',
        repeat: 'no-repeat',
        textColor: '#000',
        textShadow: 'none',
        overlayColor: '#000',
        overlayOpacity: 0,
      },
      content: {
        title: 'Your exercise vitamin',
        subtitle: 'Transform the way you exercise and make it:',
        content: '',
      },
      items: [
        {
          title: 'Consent',
          content: 'When it comes to long-term health consistency trumps intensity. Seven Movements makes building a new exercise routine easy.',
          img: '',
        },
        {
          title: 'Effective',
          content: 'Seven Movements is programmed to make you a better mover.  Combining the science of functional movement & recovery you will feel the difference just a few minutes can make.',
          img: '',
        },
        {
          title: 'Sustainable',
          content: 'Four 7-minute micro-doses of movement every day is all it takes to hit your recommended exercise targets. Just like your multi-vitamin, take your movement micro-doses every day for optimal results.',
          img: '',
        }
      ],
      cta: [{
          name: 'Get Coaching',
          url: '',
          fill: 'solid'
        }]
    };
  }


  public static getDefaultIntro() {
    return {
      content: {
        title: 'We are not moving enough',
        subtitle: '4 out of 5 adults are not hitting recommended exercise targets required for long-term health.',
        content: 'The no pain, no gain approach is not a long-term solution. Unsustainable exercise programs focused on short-term results have become the norm. It is no wonder why it so difficult to stick to an exercise routine.',
      },
      cta: {
        name: 'Start today!',
        link: '/enroll',
        fill: 'solid'
      },
      image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2Fsonny_brown_banner_no_text.jpg?alt=media&token=9fea4cdb-3f82-4063-b86a-88a7e5fa13a9',
    };
  }


  public static getDefaultBenefits() {
    return {
      content: {
        title: 'This is the Seven Movements Difference',
        subtitle: 'Learn how to micro-dose your movement through the day in 7-minute chunks. The goal is long-term health.  Consistent habits and regular routines are how we get there.',
        content: 'Many exercise programs are built around complexity and intensity which makes them unsustainable.  Seven Movements is the alternative to that. Simply take your movement dose each day to hit your baseline fundamental movement needs.  These can help you supplement your current activities or build a new movement habit.',
      },
      image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2Fsonny_brown_banner_no_text.jpg?alt=media&token=9fea4cdb-3f82-4063-b86a-88a7e5fa13a9',
    };
  }

  public static getDefaultClosingBanner() {
    return {
      background: {
        image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_bad_boy_academy%2FNAK29oQTk6A6telSKB6A_desk.jpg?alt=media&token=68567ea6-9a34-44bf-9a14-659bea137cfa',
        size: 'cover',
        position: 'center',
        repeat: 'no-repeat',
        textColor: '#000',
        textShadow: 'none',
        overlayColor: '#000',
        overlayOpacity: 0,
      },
      content: {
        title: 'Start the Academy Now',
        subtitle: 'Don\'t wait a moment more... get access to all best courses',
        subsubtitle: '',
      },
      cta: [
        {
          name: 'Enroll in Academy Now',
          url: '',
          fill: 'solid'
        },
        {
          name: 'Lifestyle Retreats',
          url: '',
          fill: 'outline'
        },
      ]
    };
  }

  public static getDefaultTestimonial() {
    return {
      content: 'I started working with ' + defaults.platform.name + ' on my fitness, nutrition, but most important, on my confidence and attitude to return as a better version of who I was before.',
      author: 'Max Power - The Simpsons',
      image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2Ftestimonial%20image.jpg?alt=media&token=2f3db5a5-32a4-4047-aec9-dc145c3a2a97',
    };
  }

  public static getDefaultEvents() {
    return {
      headline: 'Join Our Training Programs',
      background: {
        image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2FclosingImage.jpg?alt=media&token=057223c5-f46c-452e-b7b7-9b2df5ed2ada',
        size: 'contain',
        position: 'right',
        repeat: 'no-repeat',
      },
      events: [
        {
          dateStart: '01',
          dateEnd: '08',
          month: 'August',
          name: 'Summer Camp',
          location: 'Split, Croatia',
          description: '7 days basic training',
          type: 'Basic training',
          image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_bad_boy_academy%2Fzrce.jpg?alt=media&token=e2522009-ef3c-440c-b703-9bb261e73d68',
          note: 'Event has started',
        },
        {
          dateStart: '9',
          dateEnd: '16',
          month: 'August',
          name: '9\'s and 10\'s Training',
          location: '9\'s and 10\'s Camp',
          description: '7 days advanced training',
          type: 'Advanced training',
          image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_bad_boy_academy%2F10.jpg?alt=media&token=72e054b0-cbe3-4fa4-abb6-12cff9036db7',
        },
        {
          dateStart: '23',
          dateEnd: '30',
          month: 'August',
          name: 'Summer Camp',
          location: 'Barcelona, Spain',
          description: '7 days advanced training',
          type: 'Advanced training',
          image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_bad_boy_academy%2Fbrc.jpg?alt=media&token=4fc4249f-4c94-4d2a-9f47-d55443753749',
        }
      ],
    };
  }

  init(collection = 'academy') {
    console.log('%c Initialize Academies Service. Collection Set to: ', defaults.styles.coder, collection);
    this.academiesCollection = this.db.collection<Academy>(collection);
    this.academies = this.academiesCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
      ,first());
  }

  getItems() {
    console.log('%c Getting a list of all Academies... Should we be doing that right now?', defaults.styles.warn);
    return this.academies;
  }

  getItem(id, collection?: string) {
    if (!this.academiesCollection || collection) this.init(collection);
    return this.academiesCollection.doc<Academy>(id).valueChanges();
  }

  set(academy: Academy) {
    console.log('%c Saving Academy', defaults.styles.loading, this.userId);
    console.log(academy);
    return this.db.collection('academy').doc(this.userId).set(academy, { merge: true });
  }












  // TODO: WHY USE THIS COMPLICATED FUNCTION, WHEN getItem(id) is sufficient.
  getAcademyById(academyId?: string, collection = 'academy-published', that?: any): Observable<Academy> {
    let id = (academyId) ? academyId : this.userId;
    if (!id) {
      console.error('%c Academy ID can not be empty.', defaults.styles.error);
      return;
    }

    this.docRef = this.db.firestore.doc(collection +  "/" + id);

    let data;
    console.log('%c Academy loading...', defaults.styles.loading, {academyId, id, collection});
    this.docRef.get().then((doc) => {
      if (doc.exists) {
        data = doc.data() as Academy;
        console.log('%c Academy returned', defaults.styles.loaded, data);
        if (!data.id) {
          data.id = id;
          console.log('%c Academy id not attached to object. Adding now, but this might not be the best idea.', defaults.styles.heal, id);
        }
        this.academy.next(data);
        // this.academy.complete();
      } else {
        console.log('%c Academy not found...', defaults.styles.error, id);
        this.academy.next(null);
        if (that) {
          that.loadingPrograms = false;
          that.initDefaultAcademy();
        }
        // this.academy.complete();
      }
    }).catch((error) => {
      console.error('%c Error getting Academy:', defaults.styles.error, error);
      // this.academy.complete();
    });

    return this.academy.asObservable();
  }

  getMemberByUserId(userId: string, academyId: string): Observable<Member | null> {
    // Validate the inputs
    if (!userId || !academyId) {
      console.error('User ID and Academy ID must not be empty');
      return from(null); // Immediately return an Observable with null for consistency
    }

    // Construct the path to the specific member in the sub-collection
    const memberPath = `academy/${academyId}/members/${userId}`;

    // Convert the Promise returned by 'get' into an Observable using 'from'
    return from(this.db.firestore.doc(memberPath).get()).pipe(
      map(doc => {
        if (doc.exists) {
          // If the document exists, return the data cast to a Member
          return doc.data() as Member;
        } else {
          // If the document doesn't exist, log and return null
          console.log('No such member!');
          return null;
        }
      }),
      catchError(error => {
        // In case of an error, log and return an Observable with null
        console.error('Error getting member:', error);
        return from(null); // 'of' is used to return an Observable with a specific value
      })
    );
  }
  getPublicAcademies(limit = 20): Observable<any> {
    const documents = this.db.collection<Academy>(
      'academy-published',
      ref => ref.where('isPublic', '==', true)
      .limit(limit)
    ).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          const docId = a.payload.doc.id; // Duplicated to fix conflict with id
          return { id, docId, ...data } as Academy;
        });
      })
      , first()); // What about using  take(1)? I still don't understand the diff // does this even do anything?
    return documents;
  }





  async saveAcademy(academy) {
    console.log('Saving academy settings', academy);

    const loading = await this.loadingController.create({
      message: 'Saving Academy...'
    });
    await loading.present();

    academy.updatedAt = Date.now();
    this.set(academy).then(() => {
      loading.dismiss();
    });
  }

















  getAcademyDays(id?: string, cohort?: string, limit = 30): Observable<any> {
    id = (id) ? id : this.userId;
    let collection = `/academy/${id}/days/`;
    if (cohort && cohort.length > 0) collection = `/academy/${id}/cohort/${cohort}/days/`;
    if (cohort && cohort.length > 0) console.log('%c Cohort data not returned due to orderBy issue that remains unsolved.', defaults.styles.error); // TODO: TEMP ERROR
    console.log('Get Academy last days:', limit);
    console.log("collection", collection);
    const documents = this.db.collection<Academy>(
      collection,
      ref => ref
      .orderBy('updatedAt', 'desc') // TODO: causing issues, we need a timestamp for start of day startOfDayStamp
      .limit(limit)
    ).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data } as Academy;
        });
      }));
    return documents;
  }


  getAcademyYearStats(id?: string, year?: number) {
    id = (id) ? id : this.userId;
    const now = new Date;
    year = (year) ? year : now.getFullYear();

    console.log('Get Academy Stats for Year', year);
    console.log("collection", `/academy/${id}/years/`);

    const collection = this.db.collection<any>(`/academy/${id}/years/`);
    return collection.doc<any>(year.toString()).valueChanges();
  }


  getAcademyMonthStats(id?: string, month?: number, year?: number) {
    id = (id) ? id : this.userId;
    const now = new Date;
    month = (month) ? month : now.getFullYear();
    year = (year) ? year : now.getFullYear();

    console.log('Get Academy Stats for Year', month);
    console.log("collection", `/academy/${id}/years/`);

    const collection = this.db.collection<any>(`/academy/${id}/years/${year}/months/`);
    return collection.doc<any>(month.toString()).valueChanges();
  }














  // STYLES
  setStyle(property: string, value: string): void {
    const elementRef = this.elementRef;
    if (!elementRef?.nativeElement) { 
      console.log("%c Failed to set style. Element reference missing.", defaults.styles.heal, {property, value});
      return;
    }
    elementRef.nativeElement.style.setProperty(property, value);
    // console.log("Set Style", {property, value});
  }

  elementRef;
  setAllStyles(elementRef, academy) {
    if (!academy) {
      console.log('%c Academy not found. Colors not set.', defaults.styles.warn);
      return;
    }
    this.elementRef = elementRef;

    const styles = [
      // player
      ['--player-color-bg', academy.playerColorBg],
      ['--player-color-bg-darkMode', academy.playerColorBgDarkMode ?? "#272727"],
      ['--player-color-bg-rgb', HelpersService.convertHexToRGB(academy.playerColorBg)],
      ['--player-color-primary', academy.playerColorPrimary],
      ['--player-color-primary-contrast', academy?.playerColorPrimaryContrast],
      ['--player-color-light', academy.playerColorLight],
      ['--player-color-medium', academy.playerColorMedium],
      ['--player-color-dark', academy.playerColorDark],
      ['--player-color-success', academy.playerColorSuccess],
      ['--player-color-warn', academy.playerColorWarn],
      ['--player-color-danger', academy.playerColorDanger],
      ['--player-card-lightMode', academy.playerCardLightMode],
      ['--player-card-darkMode', academy.playerCardDarkMode ?? "#121212"],
      ['--player-border-radius', academy.playerBorderRadius],
      // academy
      ['--academy-color-bg', academy?.bgColor ],
      ['--academy-color-primary', academy?.colorPrimary ],
      ['--academy-hero-text-color', academy?.heroBanner?.background?.textColor],
      ['--academy-hero-text-shadow', academy?.heroBanner?.background?.textShadow],
      ['--academy-hero-overlay-color', academy?.heroBanner?.background?.overlayColor],
      ['--academy-hero-overlay-opacity', academy?.heroBanner?.background?.overlayOpacity],
      // other (consider using more agnostic color labels, and not specific to a component or element)
      ['--enrollment-screen-background-color', academy?.enrollmentScreenBackgroundColor],
      ['--bullet-background-active', academy.playerColorPrimary],
    ];

    let logOutput: any = [];
    styles.forEach(element => {
      const variable = element[0];
      const value = element[1];
      if (variable && value) {
        this.setStyle(variable, value);
        logOutput.push({variable, value});
      } else {
        console.log("%c Failed to set Style", defaults.styles.warn, {variable, value});    
      }
    });
    console.log("%c Styles set", defaults.styles.processed, logOutput);
  }





}
