import { academies } from "./academies";

export const defaults = {

    version: "1.9.2", // TODO: Also update in "package.json" (Consider only using one of these values) [search  and replace recommended!]

    //////////////////////
    // CONFIG: SET DEFAULT ACADEMY
    //
    platform: academies.sevenMovementsResearch,

    // //////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////
    // CONTINUED, THE REST OF THE CONSTANTS
    // WARNING: this is not a secure way of doing it, use security rules instead
    superAdmins: [
        'ugWavqSLcOReqV9KpVcg0Vpz4k53', // igor
        // 'Nxyycz5Y88a2LmG7PkVa3qnveuk2', // nana
        'l43FCPJgcc9srUGudXri', // slava
    ],
    images: {
        avatar: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_avatars%2Fdefault_male.svg?alt=media&token=bd0374b4-cda2-46c7-998d-cdca6cfd8b40',
        avatarFemale: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_avatars%2Fdefault_female.svg?alt=media&token=3076b3e8-69ad-449f-b9e2-cffbcf95aa48',
        solution: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/solutions%2Fsolution_empty.png?alt=media&token=9f3c6684-0510-4ad7-b094-ecd6ff8289ca',
        day: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/backgrounds%2FAfter_Noon%403x.jpg?alt=media&token=bfb661a6-367d-4564-9665-8201d1813914',
        night: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/backgrounds%2FNight%403x.jpg?alt=media&token=7c72064a-4371-4b4a-a728-58b444caac20',
        audio: '/assets/activities/audio.jpeg',
    },
    strings: {
        achievementTitle: 'Great Work Today, ##userName##!',
        achievementMessage: 'You have completed your daily dose of movement break.',
        achievementFinePrint: 'You deserve a break, go get some fresh air. See you again tomorrow.',

        biometricThankYouTitle: 'Great work, completing your movement break ##userName##.',
        biometricThankYouMessage: 'Thanks for providing your post-scores',
        biometricThankYouFinePrint: 'We will notify you at the time of your next movement break.',

biometricThankYouIcons: 
`trophy
ribbon
fitness
diamond
medal
sparkles
star`,
biometricThankYouTitles:
`Great work
Nice!
Well done
Kudos
Keep it up`,
biometricThankYouMessages:
`Nice work ##userName##! We've recorded your movements - you are right on track.
Great job, ##userName##! Your movements are spot on. Keep up the awesome work!
Fantastic effort, ##userName##! Your movements have been logged, and you're doing great!
Way to go, ##userName##! Your movements are right where they should be. Keep it up!
Excellent work, ##userName##! Your movements are on point. Keep pushing forward!`,
biometricThankYouFinePrints: '',

        activityVerb: 'Activity', // aka Meditate
        activityVerbPlural: 'Activities', // aka Meditations
        activityVerbPastTense: 'Completed Activities', // TODO: This might not be grammatically correct, or a verb (aka Meditated)

        emptyState: 'No Activity Yet',
    },
    styles: {

        // title: 'font-size: 128px; font-weight: bold; color: baby-blue; border: 1px solid #000',
        app: 'font-size: 72px; font-weight: bold; color: #0011DD; letter-spacing: -16px; -webkit-text-stroke: 1px black;',
        heading: 'font-size: 48px; font-weight: bold; color: #dcd6f7; letter-spacing: -16px; -webkit-text-stroke: 1px black;',
        subheading: 'font-size: 32px; font-weight: bold; color: #33ccff; letter-spacing: -16px; -webkit-text-stroke: 1px black;',
        
        // app: 'background: #000; color: #33ff33; font-weight: bold; font-size: 24px;',
        success: 'background: #99cc00; color: #FFF; font-weight: bold;',
        completed: 'background: #3300cc; color: #FFF; font-weight: bold;',
        coder: 'background: #000; color: #33ff33; font-weight: bold;',
        highlight: 'background: #ff66ff; color: #FFF; font-weight: bold; font-size: 24px;',
        acme: 'background: #cc0099; color: #FFF; font-weight: bold; font-size: 24px;', // Medium Violet Red
        acme2: 'background: #03ad03; color: #FFF; font-weight: bold; font-size: 24px;', // success / green
        acme3: 'background: #0341ad; color: #FFF; font-weight: bold; font-size: 24px;', // blue
        acme4: 'background: #cc6900; color: #FFF; font-weight: bold; font-size: 24px;', // depreciated / brown
        coderReminder: 'background: #000; color: orange; font-weight: bold;',
        constant: 'background: #000; color: #DDD8B8; font-weight: bold;',
        missing: 'background: #DDD; color: orange; font-weight: bold;',

        service: 'background: #000000; color: #FFF; font-weight: bold; font-size: 24px;',
        component: 'background: #000000BB; color: #7F7; font-weight: bold;',
        destroyed: 'background: #E1E6E1; color: #EC645B',

        processing: 'background: #00000066; color: #44FFD1',
        processed: 'background: #000000DD; color: #44FFD1',
        routing: 'background: #DDD; color: #3880ff',
        event: 'background: #a9f505; color: #000; font-weight bold;',

        authorize: 'background: #dcd6f7; color: #FFF',
        authorizing: 'background: #a6b1e1; color: #FFF',
        authorized: 'background: #00416d; color: #FFF',
        
        calc: 'background: #186705; color: #FFF',
        fresh: 'background: #488707; color: #FFF',
        filtered: 'background: #a3ffb4; color: #000',
        reset: 'background: #e1ff00; color: #000',

        resetProgram: 'background: #e1ff00; color: #000; font-weight: bold; font-size: 24px;',

        loading: 'background: #4367fc; color: #FFF',
        loaded: 'background: #0838fb; color: #FFF',

        writing: 'background: #cd49fc; color: #000',
        written: 'background: #bb08fb; color: #000',
        reading: 'background: #7ea9ff; color: #FFF',
        read: 'background: #4483ff; color: #FFF',

        deleting: 'background: #EC645B; color:#FFF',
        deleted: 'background: #E63B2E; color:#FFF',

        disabled: 'background: #301; color: red; font-weight: bold',
        depreciated: 'background: #996600; color: #fff; font-weight: bold',

        info: 'background: #EDFDFB; color: #28d0ff',
        error: 'background: red; color: #FFF',
        warn: 'background: orange; color: #FFF',
        resolved: 'background: yellow; color: #000',
        
        notification: 'background: purple; color: #FFF; font-weight: bold;',
        
        stop: 'background: yellow; color: red',
        heal: 'background: #ebd6ff; color: #f75f80; font-weight: bold;',
        override: 'background: #ebd6ff; color: #5f5ff7; font-weight: bold;',
        todo: 'background: #effde8; color: DarkOrange; font-weight: bold;', // #fff2d7 (yellow) #effde8 (green)
        test: 'background: #000; color: yellow; font-weight: bold;',
        testing: 'background: #000; color: yellow; font-weight: bold; font-size: 24px;',
        repair: 'background: SlateBlue; color: Orange; font-weight: bold;',

        biometric: 'background: #00000022; color: #3880ff; font-weight: bold;',
        habit: 'background: #ccffcc; color: #99cc00; font-weight: bold;',

        loggedOut: 'background: #000; color: #FFF'
    },
    toast: {
        shortDelay: 3000,
        longDelay: 6000,
        extraLongDelay: 9000,
        superExtraLongDelay: 160000,
        debugDelay: 320000,
    },
    colors: {
        gray: '#AEB2B2',
        reef: '#00d2ff',
        aqua: '#aqua',
        kyoto: '#c21500',
        success: '#50D615',
        warn: '#EFAF0A',
        danger: '#F50B0B',
    },
    rgbColors: {
        white: '255, 255, 255',
        black: '0, 0, 0',
        blue: '66, 191, 221',
        red: '173, 46, 36',
        pinko: '254,100,180',
        mediumSpringGreen: '0, 250, 154',
        green: '72, 169, 166',
        yellow: '255, 210, 90',
        brown: '165, 42, 42',
        orange: '255, 165, 0',
        violet: '238,130,238', // very close to magenta, not as bright or saturated
        magenta: '255, 0, 255',
        teal: '127, 255, 212',
        purple: '221, 160, 221',
        lime: '0,255,0',
        gray: '128,128,128',
        coral: '255,127,80',
        navy: '0,0,128',
        honeydew: '240,255,240',
        tomato: '255, 99, 71',
        thistle: '216, 191, 216',
        goldenRod: '218, 165, 32',
        lightSalmon: '255, 160, 122',
        lightGreen: '144, 238, 144',
        olive: '128, 128, 0',
        mediumPurple: '147, 112, 219',
        mediumOrchid: '186, 85, 211',
        khaki: '240, 230, 140',
        chocolate: '210, 105, 30',
    },
    programColors: [ '#3F4449', '#E58422', '#d5230b', '#716bb3', '#7A45E5' ],
    biometrics: {
        moods: {
            happy: '/assets/icons/mood/happy.svg',
        }
    },
    limits: {
        messages: 100,
        habits: 600,
        maxRecords: 3000, // = 1 year of 4 daily activity, with 2 trackables daily -> 3000 / 365 / (2 * 4)
    },
    quotes: [
        'Rise up. Start fresh. See the bright opportunity in each new day.',
        'Do or do not, there is no try',
        'The obstacle is the path.',
        'May you live every day of your life.',
        'Whatever you are, be a good one.'
    ],
    costs: {
        sms: 0.0149, // sms + carrier fees (0.0079 + 0.007)
        email: 0.00133,
    }
};
