// import { style } from '@angular/animations';
import { defaults } from 'src/constants/constants';
import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from 'src/services/auth.service';
import { take } from 'rxjs/operators';
import { HelpersService } from 'src/services/Helpers.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  userId;
  academy;

  public width;
  public height;

  constructor(
    private platform: Platform,
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public authService: AuthService,
  ) {
    this.initializeApp();

    ////////////////////////////////
    ////////////////////////////////
    // Is User Authenticated? 0.2
    this.authService.isUserAuthenticated().subscribe(res => {
      console.log('%c Authentication response', defaults.styles.authorized, res);
      if (res != null && typeof res === 'string') {
        this.userId = res;
        this.init();
      }
    }, take(1));
    ////////////////////////////////
  }

  initializeApp() {
    console.log('%c' + HelpersService.getPlatformName(false), defaults.styles.app);
    console.log(`%cInitialize application version ${defaults.version}`, defaults.styles.heading);
    this.platform.ready().then(() => {
      console.log('%c Platform Ready.', defaults.styles.component);
      
      ////////////////
      // PLATFORM TYPE
      this.setPlatforms();
      console.log('this.isDesktop()', environment.isDesktop);
      console.log('environment.isApp', environment.isApp);

      environment.width = this.platform.width();
      environment.height = this.platform.height();

      // this.splashScreen.hide();

      ////////////////
      // Detect Resume
      this.platform.resume.subscribe((e) => {
        console.log('%c Platform resume called', 'color: a0f0bc; font-weight: bold');
      });
    });
  }

  init() {
    console.log('Initialize authenticated portion of the app.');
    this.load();
  }

  load() {
    // this.loadAcademy();
  }

  // // LOAD ACADEMY
  // async loadAcademy() {
  //   console.log('%c Academy requested (via user id)', defaults.styles.loading, this.userId);
  //   this.academyService.getItem(this.userId).subscribe(academy => {
  //     this.academy = academy;
  //     console.log('%c Academy returned', defaults.styles.loaded);
  //   });
  // }

  setPlatforms() {
    environment.platforms = this.platform.platforms();
    console.log('Setting Platforms variables', environment.platforms);

    // DESKTOP?
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      environment.isApp = false; // not accurate
      environment.isDesktop = true;
    } else {
      environment.isApp = true; // not accurate
      environment.isDesktop = false;
    }

    // CORDOVA? (trying to detect when in iOS or Android app)
    if (this.platform.is('cordova')) {
      environment.isCordova = true;
    } else {
      environment.isCordova = false;
    }
  }
}
