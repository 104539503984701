// AUTOMATICALLY SET FIREBASE SOURCES
import { defaults } from "src/constants/constants";

export let firebaseSources: any;
console.log ("%c SET FIREBASE SOURCES based on firebaseProject.", defaults.styles.acme, defaults.platform.firebaseProject);
if (defaults.platform.firebaseProject === "saga-tech") {
  console.log ("%c Firebase sources set to: SAGA-TECH", defaults.styles.coder, defaults.platform.firebaseProject);
  // SAGA-TECH
  firebaseSources = {
    apiKey: "AIzaSyA0vJs2Si03wW-A10J6N374dZpBclv69RA",
    authDomain: "saga-tech.firebaseapp.com",
    databaseURL: "https://saga-tech-default-rtdb.firebaseio.com",
    projectId: "saga-tech",
    storageBucket: "saga-tech.appspot.com",
    messagingSenderId: "889426806741",
    appId: "1:889426806741:web:c75802cae9e8687cf41f5c",
    measurementId: "G-62H902W664"
  }
} else if (defaults.platform.firebaseProject === "seven-movements") {
  console.log ("%c Firebase sources set to: SEVEN-MOVEMENTS", defaults.styles.coder, defaults.platform.firebaseProject);
  // SEVEN-MOVEMENTS
  firebaseSources = {
    apiKey: "AIzaSyApNBbrsOp335qu8IAiHvRafmLlKe3-YQo",
    authDomain: "seven-movements.firebaseapp.com",
    databaseURL: "https://seven-movements.firebaseio.com",
    projectId: "seven-movements",
    storageBucket: "seven-movements.appspot.com",
    messagingSenderId: "88727172379",
    appId: "1:88727172379:web:8dbcfbea48072565",
    measurementId: "G-DKK9074PZR"
  }
} else {
  console.log ("%c Firebase sources set to: HACK-YOUR-HUMAN", defaults.styles.coder, defaults.platform.firebaseProject);
    // HACKYOURHUMAN
    firebaseSources = {
      apiKey: "AIzaSyDauzio_jOoykvHd-9PIBkrINVQnZostGY",
      authDomain: "hackyourhuman.firebaseapp.com",
      databaseURL: "https://hackyourhuman.firebaseio.com",
      projectId: "hackyourhuman",
      storageBucket: "hackyourhuman.appspot.com",
      messagingSenderId: "362273416627",
      appId: "1:362273416627:web:bd89abfbbbc0b4e4df97c6",
      measurementId: "G-TJLKG7WM50"
    }
}