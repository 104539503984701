import { defaults } from 'src/constants/constants';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable()
export class AppService {

  constructor(
    public navCtrl: NavController,
    public platform: Platform,
    private toastController: ToastController,
  ) { }

  public static isApp;
  public version = '0.1.6';

  public userId;

  initialize() {
    console.log('This should run first. Initializing application.');
    // this.checkVersion();
  }

  goBack() {
    this.navCtrl.pop();
  }

  navigateForward(url: string) {
    this.navCtrl.navigateForward(url);
  }

  navigateRoot(url: string) {
    this.navCtrl.navigateRoot(url);
  }

  setPlatform() {
    if (this.platform.is('mobileweb')) {
      AppService.isApp = false;
    } else {
      AppService.isApp = true;
    }
    console.log('isApp', AppService.isApp);
  }

  async presentToast(message, color = 'dark') {
    if (!message) {
      return;
    }
    const toast = await this.toastController.create({
      message,
      color,
      duration: defaults.toast.shortDelay,
    });
    toast.present();
  }
}
