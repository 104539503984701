// import { HttpClient } from '@angular/common/http';
import { CurrencyPipe, KeyValue } from '@angular/common';
import { defaults } from 'src/constants/constants';

export class HelpersService {

  constructor(
    // private http: HttpClient,
  ) { }

  // DATES
  /*
  ██████████     █████████   ███████████ ██████████  █████████ 
  ░░███░░░░███   ███░░░░░███ ░█░░░███░░░█░░███░░░░░█ ███░░░░░███
   ░███   ░░███ ░███    ░███ ░   ░███  ░  ░███  █ ░ ░███    ░░░ 
   ░███    ░███ ░███████████     ░███     ░██████   ░░█████████ 
   ░███    ░███ ░███░░░░░███     ░███     ░███░░█    ░░░░░░░░███
   ░███    ███  ░███    ░███     ░███     ░███ ░   █ ███    ░███
   ██████████   █████   █████    █████    ██████████░░█████████ 
  ░░░░░░░░░░   ░░░░░   ░░░░░    ░░░░░    ░░░░░░░░░░  ░░░░░░░░░                                                                
  */

  // Server timestamp or local date in milliseconds
  public static toDate(date) {
    return this.getDate(date);
  }
  public static getDate(date) {
    if (!date) return date; // if undefined, simply return

    // Timestamp from server
    if (date?.seconds) {
      const milliseconds = date.seconds * 1000;
      return milliseconds;
    }
    // Date in seconds, convert to milliseconds
    if (date.toString().length < 11) {
      date = date * 1000;
    }
    return date;
  }

  public static convertDateToDayOfWeek(timestamp) {
    const a = new Date(timestamp);
    const dayOfWeek = HelpersService.getDayOfWeek(a.getDay());
    return dayOfWeek;
  }

  public static getMonthName(month: number) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
    return monthNames[month];
  }

  public static getMonthNameShort(month: number) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthNames[month];
  }

  public static getDayOfWeek(day: number) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[day];
  }

  public static getDayOfWeekShort(day: number) {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'];
    return days[day];
  }

  public static getDateLabel(date) {
    const dateLabel = HelpersService.getDayOfWeekShort(date.getDay()) +
      '/' + (Number(date.getMonth()) + 1) +
      '/' + date.getDate();
    return dateLabel;
  }

  public static getTodaysDate(d = new Date()) {
    const dateString = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    return dateString;
  }

  public static showDate(stamp) {
    const d = new Date(stamp);
    return this.getTodaysDate(d);
  }

  public static showTimestamp(timestamp) {
    const d = new Date(HelpersService.getDate(timestamp));
    return this.toLocaleString(d);
  }

  public static getLocalTimeObject(date = new Date()) {
    return {
      year: date.getFullYear(),
      month: date.getMonth(),
      date: date.getDate(),
      day: date.getDay(),
      hours: date.getHours(),
      min: date.getMinutes(),
    };
  }

  public static toLocaleString(date: Date | undefined) {
    if (!date) return;
    return date.toLocaleString("en-us", {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
    });
  }

  public static getDateAtTimezoneOffset(timestamp, timezoneOffset) {
    const date = new Date(timestamp);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() - timezoneOffset, date.getSeconds());
    // return new Date(date.getTime() - (timezoneOffset * 60000));
  }

  public static getDaysAgoDate(daysAgo, d = new Date()) {
    // const d = new Date();
    d.setDate(d.getDate() - daysAgo);
    d.setHours(0, 0, 0);
    d.setMilliseconds(0);
    return +d;
  }

  public static getDaysInFutureDate(daysAgo, dateStamp) {
    const d = new Date(dateStamp);
    d.setDate(d.getDate() + daysAgo);
    d.setHours(0, 0, 0);
    d.setMilliseconds(0);
    return HelpersService.getTodaysDate(d);
  }

  public static daysBetween(date1, date2, absolute = true) {
    const ONE_DAY = 1000 * 60 * 60 * 24; // The number of milliseconds in one day
    const differenceInMilliseconds = absolute ? Math.abs(date1 - date2) : date1 - date2;
    return Math.round(differenceInMilliseconds / ONE_DAY);
}

public static sameDay(d1, d2) {
  return  d1.getFullYear()  ===   d2.getFullYear()    &&
          d1.getMonth()     ===   d2.getMonth()       &&
          d1.getDate()      ===   d2.getDate();
}

public static daysAgo(date) {
  const seconds = Math.floor(( Date.now() - date) / 1000);
  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + ' years';
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + ' months';
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' days';
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' hours';
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
}

public static showTime(hours, minutes) {
  let min: string = minutes.toString();
  if (min.length < 2) min = "0" + min;
  min = min + "00";
  min = min.slice(0, 2);
  return hours + ":" + min;
}

public static getCurrentISOWeekNumber(): number {
  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 1);
  const days = Math.floor((now.getTime() - startOfYear.getTime()) / (24 * 60 * 60 * 1000));
  const weekNumber = Math.ceil((days + startOfYear.getDay() + 1) / 7);
  return weekNumber;
}

public static getCurrentDayOfYear(): number {
  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 1);
  const dayOfYear = Math.floor((now.getTime() - startOfYear.getTime()) / (24 * 60 * 60 * 1000)) + 1;
  return dayOfYear;
}

// MATH
/*
 ██████   ██████   █████████   ███████████ █████   █████
░░██████ ██████   ███░░░░░███ ░█░░░███░░░█░░███   ░░███ 
 ░███░█████░███  ░███    ░███ ░   ░███  ░  ░███    ░███ 
 ░███░░███ ░███  ░███████████     ░███     ░███████████ 
 ░███ ░░░  ░███  ░███░░░░░███     ░███     ░███░░░░░███ 
 ░███      ░███  ░███    ░███     ░███     ░███    ░███ 
 █████     █████ █████   █████    █████    █████   █████
░░░░░     ░░░░░ ░░░░░   ░░░░░    ░░░░░    ░░░░░   ░░░░░ 
*/

  public static getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
  }

  public static normalize(val, min, max) {
    const delta = max - min;
    return (val - min) / delta;
  }

  public static isNumber(n){
    return typeof n == 'number' && !isNaN(n) && isFinite(n);
  }

  public static generateNumberArray(min: number, max: number): number[] {
    const numbers = [];
    for (let i = min; i <= max; i++) {
      numbers.push(i);
    }
    return numbers;
  }

  // Convert from decimal input to integer for Stripe
  public static convertToInteger(priceDecimal) {
    return Math.round(priceDecimal * 100);
  }

  // Convert from integer stored in the database to decimal for display
  public static convertToDecimal(priceInteger) {
    return (priceInteger / 100).toFixed(2);
  }

  // CHART
  /*
   █████████  █████   █████   █████████   ███████████   ███████████
  ███░░░░░███░░███   ░░███   ███░░░░░███ ░░███░░░░░███ ░█░░░███░░░█
 ███     ░░░  ░███    ░███  ░███    ░███  ░███    ░███ ░   ░███  ░ 
░███          ░███████████  ░███████████  ░██████████      ░███
░███          ░███░░░░░███  ░███░░░░░███  ░███░░░░░███     ░███
░░███     ███ ░███    ░███  ░███    ░███  ░███    ░███     ░███
 ░░█████████  █████   █████ █████   █████ █████   █████    █████
  ░░░░░░░░░  ░░░░░   ░░░░░ ░░░░░   ░░░░░ ░░░░░   ░░░░░    ░░░░░
  */
  public static average(ctx, index = 0) {
    const values = ctx.chart.data.datasets[index].data;
    return values.reduce((a, b) => a + b, 0) / values.length;
  }

  public static getAnnotation(color: string, index = 0, position = "start") {
    return {
      type: 'line',
      borderColor: HelpersService.rgba(color, 1),
      borderDash: [6, 6],
      borderDashOffset: 0,
      borderWidth: 3,
      label: {
        display: true,
        backgroundColor: HelpersService.rgba(color, 1),
        drawTime: 'afterDatasetsDraw',
        color: 'white',
        content: (ctx) => 'Average: ' + HelpersService.average(ctx, index).toFixed(2),
        position,
      },
      scaleID: 'y',
      value: (ctx) => HelpersService.average(ctx, index)
    }
  }

  public static getDataset(label: string, data, color: string, tension = 0.2, opacity = 0.4, borderDash = []): any {
    return {
      label,
      fill: false,
      tension,
      backgroundColor: HelpersService.rgba(color, opacity),
      borderColor: HelpersService.rgba(color, 1),
      borderCapStyle: 'butt',
      borderDash,
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: HelpersService.rgba(color, opacity),
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: HelpersService.rgba(color, opacity),
      pointHoverBorderColor: HelpersService.rgba('white', 1),
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data, // : [65, 59, 80, 81, 56, 55, 40, 10, 5, 50, 10, 15],
      spanGaps: false,
    };
  }

// OBJECTS
 /*
    ███████    ███████████        █████ ██████████   █████████  ███████████  █████████ 
  ███░░░░░███ ░░███░░░░░███      ░░███ ░░███░░░░░█  ███░░░░░███░█░░░███░░░█ ███░░░░░███
 ███     ░░███ ░███    ░███       ░███  ░███  █ ░  ███     ░░░ ░   ░███  ░ ░███    ░░░ 
░███      ░███ ░██████████        ░███  ░██████   ░███             ░███    ░░█████████ 
░███      ░███ ░███░░░░░███       ░███  ░███░░█   ░███             ░███     ░░░░░░░░███
░░███     ███  ░███    ░███ ███   ░███  ░███ ░   █░░███     ███    ░███     ███    ░███
 ░░░███████░   ███████████ ░░████████   ██████████ ░░█████████     █████   ░░█████████ 
   ░░░░░░░    ░░░░░░░░░░░   ░░░░░░░░   ░░░░░░░░░░   ░░░░░░░░░     ░░░░░     ░░░░░░░░░  
 */
  
   public static isObject(variable) {
    if (!variable) return false;
    if (typeof variable === 'object' && variable !== null) {
      return true;
    }
    return false;
  }

  public static removeObjectProperty(originalObject: any, property: string) {
    let object = {...originalObject};
    let originalProgramId;
    if (object.hasOwnProperty(property)) {
      originalProgramId = object[property];
      delete object[property];
      console.log("%c Successfully removed object property: " + property, defaults.styles.success, object);
    } else {
      console.log("%c Failed to remove object property. Property not found", defaults.styles.warn, object);
    }
    return object;
  }

    //var csv is the CSV file with headers
    public static csvToJSON(csv, delimiter = ","){
      if (delimiter) {
        delimiter = delimiter;
        console.log('%c Using a custom delimiter', defaults.styles.fresh, delimiter);
      }
  
      var lines=csv.split("\n");
      var result = [];
      var headers=lines[0].split(delimiter);
  
      for(var i=1;i<lines.length;i++){
          var obj = {};
          var currentLine=lines[i].split(delimiter);
          for(var j=0;j<headers.length;j++){
              obj[headers[j]] = currentLine[j];
          }
          result.push(obj);
      }
  
      return result; //JavaScript object
      // return JSON.stringify(result); //JSON string
    }

// ARRAYS
/*
   █████████   ███████████   ███████████     █████████   █████ █████  █████████ 
  ███░░░░░███ ░░███░░░░░███ ░░███░░░░░███   ███░░░░░███ ░░███ ░░███  ███░░░░░███
 ░███    ░███  ░███    ░███  ░███    ░███  ░███    ░███  ░░███ ███  ░███    ░░░ 
 ░███████████  ░██████████   ░██████████   ░███████████   ░░█████   ░░█████████ 
 ░███░░░░░███  ░███░░░░░███  ░███░░░░░███  ░███░░░░░███    ░░███     ░░░░░░░░███
 ░███    ░███  ░███    ░███  ░███    ░███  ░███    ░███     ░███     ███    ░███
 █████   █████ █████   █████ █████   █████ █████   █████    █████   ░░█████████ 
░░░░░   ░░░░░ ░░░░░   ░░░░░ ░░░░░   ░░░░░ ░░░░░   ░░░░░    ░░░░░     ░░░░░░░░░  
*/

  public static getUniqueKeysFromChildren(array, bucket) {
    const allKeys = [];
    array.forEach(element => {
      if (element.hasOwnProperty(bucket)) {
        allKeys.push(...Object.keys(element[bucket]));
      }
    });
    console.log('%c all keys', defaults.styles.calc, allKeys);
    // unique only
    const keys = [...new Set(allKeys)];
    console.log('%c keys', defaults.styles.calc, keys);
    return keys;
  }

  public static getUniqueKeys(array) {
    const allKeys = [];
    array.forEach(element => {
      allKeys.push(...Object.keys(element));
    });
    console.log('%c All the keys scanned', defaults.styles.calc, allKeys);
    // unique only
    const keys = [...new Set(allKeys)];
    console.log('%c Unique keys', defaults.styles.calc, keys);
    return keys;
  }

  public static getUniqueKeysWithSum(array) {
    const uniqueKeys = [];
    array.forEach(element => {
      const myKeys = Object.keys(element);
      myKeys.forEach(key => {
        if (!isNaN(element[key])){
          if (!uniqueKeys[key]) uniqueKeys[key] = 0;
          uniqueKeys[key] += +element[key];
        }
      })
    });
    console.log('%c Unique keys with sum calc completed', defaults.styles.calc, uniqueKeys);
    return uniqueKeys;
  }

  public static getValuesFromKey(array, key) {
    const allValues = [];
    array.forEach(element => {
      if (element[key]) {
        allValues.push(element[key]);
      }
    });
    console.log('%c All values from key: ' + key, defaults.styles.calc, allValues);
    return allValues;
  }

  public static getUniqueValuesFromKey(array, key) {
    const allValues = HelpersService.getValuesFromKey(array, key);
    const uniqueValues = [...new Set(allValues)];
    console.log('%c Unique values from key: ' + key, defaults.styles.calc, uniqueValues);
    return uniqueValues;
  }

  public static getUniqueValuesFromKeyWithCounts(array, key) {
    const arrayWithCounts = HelpersService.getValuesFromKey(array, key);
    return HelpersService.countUniqueStringsInArray(arrayWithCounts);
  }

  public static countUniqueStringsInArray(arr) {
    let count = {};
    arr.forEach(str => {
      count[str] = (count[str] || 0) + 1;
    });
    return count;
  }

  public static getRandomRecord(array) {
    let randomNumber = Math.floor(Math.random() * array.length);
    return array[randomNumber];
  }

  public static getRecordsBeforeDate(array, date) {
    const results = array.filter(item => {
        return item.date >= date;
    });
    return results;
  }

  public static getRecordsByProperty(array, property: string,  value: string | boolean | number, notEqual = false) {
    if (!array || array.length < 1) {
      console.log('%c Failed to get records by property as array is null or empty: ' + property, defaults.styles.heal, array);
      return;
    }
    const results = array.filter(item => {
        return notEqual ? item[property] != value : item[property] === value;
    });
    return results;
  }

  public static getRecordsByCategory(array, category: string) {
    const results = array.filter(item => {
        return item.category === category;
    });
    return results;
  }

  public static getRecordsBySubCategory(array, subcategory: string) {
    const results = array.filter(item => {
        return item.subcategory === subcategory;
    });
    return results;
  }

  public static getRecordsById(array, id) {
    // console.log('id', id);
    if (!array) {
      console.log('%c Array empty. Get record by ID not able to run.', defaults.styles.error);
      return;
    }
    const results = array.filter(item => {
        return item.id === id;
    });
    return results;
  }

  public static getRecordById(array, id) {
    if (!array) {
      console.error("getRecordById() FAILED. Missing array to perform lookup on.", array);
      return;
    }
    if (HelpersService.isEmpty(id)) {
      console.error("getRecordById() FAILED. id can not be empty.", id);
      return;
    }
    const records = this.getRecordsById(array, id);
    let record;
    if (records) {
      // console.log("%c Records found", defaults.styles.app, record);
      record = records[0];
      if (record) {
        // console.log("%c Record found", defaults.styles.app, record);
        return record;
      }
    }
    return;
  }

  public static getRecordsByName(array, name) {
    if (!array) {
      console.log('%c Array empty. Get record by name not able to run.', defaults.styles.error);
      return;
    }
    const results = array.filter(item => {
        return item.name === name;
    });
    return results;
  }

  public static getIndexBySlug(array, slug) {
    // console.log('id', id);
    const results = array.findIndex(item => {
        return item.slug === slug;
    });
    return results;
  }

  public static getChecked(array) {
    const results = array.filter(item => {
        return item.checked === true;
    });
    return results;
  }

  public static mergeObjects(obj1, obj2) {
    // merges only the missing properties
    // without overwriting existing properties
    return {...obj1, ...obj2};
  }

  public static normalizeNumbers(numbers) {
    const ratio = Math.max.apply(Math, numbers) / 100;
    numbers = numbers.map( (v) => Math.round(v / ratio));
    return numbers;
  }

  public static shuffleArray(array) {
    if (!array) { return; }
    console.log('Shuffle array', array);
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
  }

  public static removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  //Additional function for case-Insensitive comparison
  public static removeItemOnceCaseInsensitive(arr, value){
    var index = arr.findIndex(item => value.toLowerCase() === item.toLowerCase());
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  //Additional function for removing item from multidimesional array
  public static removeItemOnceFor2d(arr, key, value) {
    for (var i = 0; i <= arr.length - 1; i++) {
      if (arr[i][key] == value) {
        arr.splice(i, 1);
        break;
      }
    }
    return arr;
  }

  public static removeItems(arr, items) {
    items.forEach(value => {
      var index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
    });
    return arr;
  }

  public static findUndefinedVal(object, removeUndefinedValues = false) {
    // console.log('%c FIND UNDEFINED VALUES', defaults.styles.processing, object);
    if (!object || object === undefined) {
      // console.log('%c OBJECT EMPTY, ISSUE', defaults.styles.error, object);
      return;
    }
    Object.keys(object).forEach(k => {
      // console.log('SEARCHING KEY', k);
      if (typeof object[k] === 'undefined') {
        console.log('%c UNDEFINED VALUE for KEY', defaults.styles.warn, k);
        if (removeUndefinedValues) {
          console.log('%c UNDEFINED VALUE REMOVED', defaults.styles.deleted, k);
          delete object[k];
        }
        return null;
      }
      if (typeof object[k] === 'object') {
        const value = HelpersService.findUndefinedVal(object[k]);
        if (value) return value;
      }
    })
    return null;
  }

  public static filterUndefined(obj) {
    const undefinedValuesFound = [];
    for (const key in obj) {
      if (obj[key] === undefined) {
        undefinedValuesFound.push({key});
        delete obj[key];
        continue;
      }
      if (obj[key] && typeof obj[key] === 'object') {
        this.filterUndefined(obj[key]);
        if (!Object.keys(obj[key]).length) {
          undefinedValuesFound.push({key});
          delete obj[key];
        }
      }
    }
    if (undefinedValuesFound.length > 0) {
      console.log(`%c ${undefinedValuesFound.length} undefined values found`, defaults.styles.heal, undefinedValuesFound);
    } else {
      console.log("%c filterUndefined() ran without any results (process intense function)", defaults.styles.processed);
    }
    return obj;
  }

  public static compareObjects(obj1, obj2, propertyToSkip?, compareParityPropertiesOnly = true, verboseLog = false) {
    let count = 0;
    let mismatchedProps = [];

    for (const prop in obj1) {
      if (prop === propertyToSkip) continue;
      if (obj1.hasOwnProperty(prop) && obj2.hasOwnProperty(prop) || !compareParityPropertiesOnly) {
        if (obj1[prop] !== obj2[prop]) {
          count++;
          mismatchedProps.push({
            property: prop,
            value1: obj1[prop],
            value2: obj2[prop]
          });
        }
      }
    }

    if (count === 0) {
      if (verboseLog) console.log('All properties match!');
    } else {
      console.log(`${count} properties do not match:`);
      console.table(mismatchedProps);
    }

    return count;
  }

  // public static arrayCounter(number: number) {
  //   return new Array(number);
  // }

  public static count(n: number): Array<number> {
    if (HelpersService.isNumber(n)) {
      return Array(n);
    } else {
     console.log('%c NOT A NUMBER', defaults.styles.error);
    }
  }

  public static incrementArrayItemAtIndex(array, index) {
    if (!array) array = [];

    console.log("INDEX", index);
    if (!array.hasOwnProperty(index)) {
      array[index] = 1;
      console.log("INDEX -> CREATED FOR PROGRAM", index);
    } else {
      array[index]++;
      console.log("INDEX -> UPDATED FOR PROGRAM", index);
    }

    return array;
  }


  // array.sort(dynamicSort("Name"));
  public static dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
        * and you may want to customize it to your needs
        */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  public static dynamicSortForcedNumeric(property) {
    console.log("%c PROCESS INTENSE FUNCTION. REPLACE in 2 months (from Dec 19 2022) after createdAd date has been added to all usage logs.", defaults.styles.warn)
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
        * and you may want to customize it to your needs
        */
        const aa = parseInt(a[property]);
        const bb = parseInt(b[property]);
        let result = (aa < bb) ? -1 : (aa > bb) ? 1 : 0;
        return result * sortOrder;
    }
  }

  public static searchObjectForTerm(item, filterTerm, searchFields) {
    let hasFound = false;
    for (const field of searchFields) {
      let keys = field.split('.');
      let currentObject = item;
      keys.forEach(key => {
        if (currentObject.hasOwnProperty(key)) {
          currentObject = currentObject[key];
        }
      });
      if (!currentObject) continue;
      const indexOf = currentObject.toString().toLowerCase().indexOf(filterTerm.toLowerCase());
      // console.log(`Search field: [${field}] with value: [${currentObject}] for filterTerm: [${filterTerm}]. Index of: ${indexOf}`);
      if (indexOf > -1) {
        hasFound = true
        break;
      }
    };
    return hasFound;
  }

  /*
  ///////////////////
  COMPARER FUNCTIONS
  Usage:

  <div *ngFor="let item of object | keyvalue: originalOrder">
    {{item.key}} : {{item.value}}
  </div>
  */

  // Preserve original property order
  public static originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  // Order by ascending property value
  public static valueAscOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return a.value.localeCompare(b.value);
  }

  // Order by descending property key
  public static keyDescOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  }


// COLOR
/*
   █████████     ███████    █████          ███████    ███████████  
  ███░░░░░███  ███░░░░░███ ░░███         ███░░░░░███ ░░███░░░░░███ 
 ███     ░░░  ███     ░░███ ░███        ███     ░░███ ░███    ░███ 
░███         ░███      ░███ ░███       ░███      ░███ ░██████████  
░███         ░███      ░███ ░███       ░███      ░███ ░███░░░░░███ 
░░███     ███░░███     ███  ░███      █░░███     ███  ░███    ░███ 
 ░░█████████  ░░░███████░   ███████████ ░░░███████░   █████   █████
  ░░░░░░░░░     ░░░░░░░    ░░░░░░░░░░░    ░░░░░░░    ░░░░░   ░░░░░ 
*/

  public static colors = [
    "primary",
    "secondary",
    "tertiary",
    "success",
    "warning",
    "danger",
    "dark",
    "medium",
    "light"
  ]
  public static rgba(colorName: string, opacity?: number ) {
    if (!opacity) { opacity = 1; }
    return 'rgba(' + defaults.rgbColors[colorName] + ',' + opacity + ')';

  }

  public static convertHexToRGB(hex: string) {
    if (!hex) {
      console.log('%c Failed to convert Hex To RGB. Hex value missing.', defaults.styles.error, hex);
      return "128, 128, 128";
    }
    hex = hex.replace(/#/g, '');
    if (hex.length === 3) {
        hex = hex.split('').map(function (hex) {
            return hex + hex;
        }).join('');
    }
    // validate hex format
    var result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})[\da-z]{0,0}$/i.exec(hex);
    if (result) {
        var red = parseInt(result[1], 16);
        var green = parseInt(result[2], 16);
        var blue = parseInt(result[3], 16);

        // return [red, green, blue]; // array
        // return 'rgb(' + red + ', ' + green + ', ' + blue + ')'; // css
        return red + ', ' + green + ', ' + blue; // rgb
    } else {
        // invalid color
        console.log('%c ERROR. Not a hex value', defaults.styles.error, hex);
        return null;
    } 
  }

  public static isColorLight(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
}
  
// STRINGS
/*
  █████████  ███████████ ███████████   █████ ██████   █████   █████████   █████████ 
 ███░░░░░███░█░░░███░░░█░░███░░░░░███ ░░███ ░░██████ ░░███   ███░░░░░███ ███░░░░░███
░███    ░░░ ░   ░███  ░  ░███    ░███  ░███  ░███░███ ░███  ███     ░░░ ░███    ░░░ 
░░█████████     ░███     ░██████████   ░███  ░███░░███░███ ░███         ░░█████████ 
 ░░░░░░░░███    ░███     ░███░░░░░███  ░███  ░███ ░░██████ ░███    █████ ░░░░░░░░███
 ███    ░███    ░███     ░███    ░███  ░███  ░███  ░░█████ ░░███  ░░███  ███    ░███
░░█████████     █████    █████   █████ █████ █████  ░░█████ ░░█████████ ░░█████████ 
 ░░░░░░░░░     ░░░░░    ░░░░░   ░░░░░ ░░░░░ ░░░░░    ░░░░░   ░░░░░░░░░   ░░░░░░░░░  
*/

  public static isString(string) {
    return (typeof string === 'string' || string instanceof String);
  }
  public static isEmpty(value) {
    return (value == null || (typeof value === "string" && value.trim().length === 0));
  }

  public static getNonEmptyString(str1: string, str2: string) {
    if (str1 && str1.trim() !== '') {
        return str1;
    } else if (str2 && str2.trim() !== '') {
        return str2;
    } else {
        return null; // or any other value or message indicating both strings are empty
    }
}

  public static getActivityVerb(academy?, plural = true, pastTense = false) {
    if (pastTense) {
      return academy?.activityVerbPastTense ?? defaults.strings.activityVerbPastTense ?? "Completed";
    }
    if (plural) {
      return academy?.activityVerbPlural ?? defaults.strings.activityVerbPlural ?? "Activities";
    }
    return academy?.activityVerb ?? defaults.strings.activityVerb ?? "Activity";
  }

  public static validateEmail(email) {
    console.log('%c VALIDATE EMAIL', defaults.styles.processing, email);
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  public static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public static toTitleCase(string) {
    var i, j, str, lowers, uppers;
    str = string.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  
    // Certain minor words should be left lowercase unless 
    // they are the first or last words in the string
    lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At', 
    'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];
    for (i = 0, j = lowers.length; i < j; i++)
      str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'), 
        function(txt) {
          return txt.toLowerCase();
        });
  
    // Certain words such as initialisms or acronyms should be left uppercase
    uppers = ['Id', 'Tv', 'Bc', 'Ss'];
    for (i = 0, j = uppers.length; i < j; i++)
      str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'), 
        uppers[i].toUpperCase());
  
    return str;
  }

  public static splitCamelCaseWithAbbreviations(s){
    if (!s) return s;
    return s.split(/([A-Z][a-z]+)/).filter(function(e){return e}).join(' ');
  }

// Didn't work on iPhone or iOS Safari
  // public static getFirstSentenceOLD(paragraph) {
  //   // Use regular expression to split the paragraph into sentences
  //   const sentences = paragraph.split(/(?<=[.?!])\s+(?=[A-Z])/);

  //   // Get the first sentence and trim any whitespace
  //   const firstSentence = sentences[0].trim();

  //   // Find the index of the end of the first sentence
  //   const endOfSentence = paragraph.indexOf(firstSentence) + firstSentence.length;

  //   // Get the punctuation at the end of the first sentence
  //   const punctuation = paragraph.slice(endOfSentence).match(/^[.?!]/);

  //   // Return the first sentence with the punctuation
  //   return punctuation ? firstSentence + punctuation[0] : firstSentence;
  // }

  public static getFirstSentence(paragraph) {
    // Use regular expression to match the first sentence
    const match = paragraph.match(/^.+?[.?!](\s|$)/);
    
    if (match) {
      // Return the first sentence
      return match[0].trim();
    }
    
    // If no sentence is found, return the entire paragraph
    return paragraph;
  }

  public static countWhiteSpaces(str) {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (str[i].match(/\s/)) {
        count++;
      }
    }
    return count;
  }

  public static slugify(str: string) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
    const to   = 'aaaaaeeeeeiiiiooooouuuunc------';
    for (let i = 0, l = from.length; i < l; i++) {
         str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

    console.warn('Slug convert function only considers latin characters, none of the cyrillic characters will work.');
    console.log('Slug created for academy', str);
    return str;
  }

  // JSON OBJ to STRING
  public static toString(obj: any, multiLine = false) {
    // stores the JSON tree in a string variable
    if (typeof obj === "object" && obj !== null) {
      let json = '';
      const separator = multiLine ? '\n' : ", ";
      Object.keys(obj).forEach((key, index, value) => {
        const line = '[ ' + key + ' ] ' + obj[key] + separator;
        json += line;
      });
      json = json.substring(0, json.length - separator.length);
      return json;
    } else {
      return obj.toString();
    }
  }

  public static getYouTubeVideoId(url: string) {
    if (!url) {
      // console.log("Get Youtube Video ID form URL failed. URL undefined", url);
      return;
    }

    // A) Assume regular youtube link with params v=youTubeVideoId
    let split = url.split("?");
    console.log('Split URL', split);
    if (split.length > 1) {
      const urlParams = new URLSearchParams(split[1]);
      const myParam = urlParams.get('v');
      console.log('%c YouTube Video ID Extracted from query string', defaults.styles.success, myParam);
      return myParam;
    }

    // B) Might be YouTube short-link instead: https://youtu.be/NbhqYdnDxDU
    split = url.split(".be/");
    if (split.length > 0) {
      const youtubeId = split[1];
      if (youtubeId) {
        console.log('%c youtu.be short-link detected and youtubeId returned', defaults.styles.heal, youtubeId);
        return youtubeId;
      }
    }

    // FAILED, return original string
    console.log('%c YouTube Video ID Failed to be Extracted from URL string', defaults.styles.warn, url);
    return url;
  }

  public static trim(str: string, length = 6) {
    return str.slice(0, length) + "...";
  }

  // COMPARE VERSIONS
  //
  // Example usage
  // const version1 = "0.6.4-a-5";
  // const version2 = "0.6.3";
  // const result = compareVersions(version1, version2);
  // if (result > 0) {
  //     console.log(`${version1} is greater than ${version2}`);
  // } else if (result < 0) {
  //     console.log(`${version1} is less than ${version2}`);
  // } else {
  //     console.log(`${version1} is equal to ${version2}`);
  // }
  public static compareVersions(version1, version2) {
    if (!version1 || !version2) {
      console.log(`%c Could not compare Versions because one or both version values are missing`, defaults.styles.warn, {version1, version2});
      return 0;
    }

    // Split the version strings into arrays of numeric and pre-release identifiers
    const [num1, pre1] = version1.split('-');
    const [num2, pre2] = version2.split('-');

    // Split the numeric parts into arrays of individual version numbers
    const nums1 = num1.split('.').map(Number);
    const nums2 = num2.split('.').map(Number);

    // Compare the numeric parts
    for (let i = 0; i < Math.max(nums1.length, nums2.length); i++) {
        const num1 = nums1[i] || 0;
        const num2 = nums2[i] || 0;

        if (num1 > num2) {
            return 1; // version1 is greater
        } else if (num1 < num2) {
            return -1; // version2 is greater
        }
    }

    // Compare the pre-release identifiers
    if (pre1 && !pre2) {
        return -1; // version1 is greater (pre-release versions are considered lower)
    } else if (!pre1 && pre2) {
        return 1; // version2 is greater
    } else if (pre1 && pre2) {
        // Compare pre-release identifiers as strings
        return pre1.localeCompare(pre2);
    }

    // Versions are equal
    return 0;
}

public static downloadObjectAsJson(filename, dataObjToWrite) {
  const blob = new Blob([JSON.stringify(dataObjToWrite)], { type: "text/json" });
  const link = document.createElement("a");

  link.download = filename;
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

  const evt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
  });

  link.dispatchEvent(evt);
  link.remove()
};

public static getProgramSubscriptionPrice(program, displayCurrency = true) {
  const currencyPipe = new CurrencyPipe('en-US');

  if (!program) {
    console.log("%c Error, program undefined. Pricing can not be determined.", defaults.styles.error);
    return;
  }

  let price;
  if (displayCurrency) {
    price = program.price;
  } else {
    price = currencyPipe.transform(program.price); // $
  }

  let term = "";
  if (program.pricePlan === 'subscription') {
    term = " " + this.getBillingPeriodTermFromProgram(program);
  } else if (program.pricePlan === 'free') {
    return "Free";
  }
  let outputString;
  if (displayCurrency) {
    // Manually add the currency code as prefix
    let currencyPrefix = '';
    switch (program.currency?.toUpperCase()) {
      case 'USD':
        currencyPrefix = 'US$';
        break;
      case 'CAD':
        currencyPrefix = 'CA$';
        break;
      case 'EUR':
        currencyPrefix = '€';
        break;
      case 'GBP':
        currencyPrefix = '£';
        break;
      default:
        currencyPrefix = `${program.currency}$`;
    }
    outputString = currencyPrefix + price + term; // US$ / CS$ / € / £
  } else {
    outputString = price + term; // $ or USD
  }
  return outputString;
}
public static getBillingPeriodTermFromProgram(program) {
  return this.getBillingPeriodTerm(program.billingInterval ?? 'month', program.intervalCount ?? 1);
}
public static getBillingPeriodTerm(billingPeriod, interval) {
  const periods = {
      day: { singular: 'daily', multiple: 'days' },
      week: { singular: 'weekly', multiple: 'weeks' },
      month: { singular: 'monthly', multiple: 'months' },
      year: { singular: 'annually', multiple: 'years' }
  };

  const specialCases = {
      day: {
          7: 'weekly',
          30: 'monthly',
          365: 'annually'
      },
      week: {
          2: 'biweekly',
          4: 'monthly'
      },
      month: {
          3: 'quarterly',
          6: 'semiannually',
          12: 'annually'
      },
      year: {
          3: 'triannual'
      }
  };

  if (specialCases[billingPeriod] && specialCases[billingPeriod][interval]) {
      return specialCases[billingPeriod][interval];
  }

  if (interval === 1) {
      return periods[billingPeriod].singular;
  } else {
      return `${interval} ${periods[billingPeriod].multiple}`;
  }
}


  // // Example usage:
  // console.log(this.getBillingPeriodTerm("day", 7));   // Output: "weekly"
  // console.log(this.getBillingPeriodTerm("month", 3)); // Output: "quarterly"
  // console.log(this.getBillingPeriodTerm("week", 2));  // Output: "biweekly"
  // console.log(this.getBillingPeriodTerm("year", 3));  // Output: "triannual"
  // console.log(this.getBillingPeriodTerm("day", 15));  // Output: "15 days"


// BROWSER
/*
 ███████████  ███████████      ███████    █████   ███   █████  █████████  ██████████ ███████████  
░░███░░░░░███░░███░░░░░███   ███░░░░░███ ░░███   ░███  ░░███  ███░░░░░███░░███░░░░░█░░███░░░░░███ 
 ░███    ░███ ░███    ░███  ███     ░░███ ░███   ░███   ░███ ░███    ░░░  ░███  █ ░  ░███    ░███ 
 ░██████████  ░██████████  ░███      ░███ ░███   ░███   ░███ ░░█████████  ░██████    ░██████████  
 ░███░░░░░███ ░███░░░░░███ ░███      ░███ ░░███  █████  ███   ░░░░░░░░███ ░███░░█    ░███░░░░░███ 
 ░███    ░███ ░███    ░███ ░░███     ███   ░░░█████░█████░    ███    ░███ ░███ ░   █ ░███    ░███ 
 ███████████  █████   █████ ░░░███████░      ░░███ ░░███     ░░█████████  ██████████ █████   █████
░░░░░░░░░░░  ░░░░░   ░░░░░    ░░░░░░░         ░░░   ░░░       ░░░░░░░░░  ░░░░░░░░░░ ░░░░░   ░░░░░ 
*/

  public static openTab(url: string){
    window.open(url, "_blank");
  }

  public static openExternalLink(url) {
    window.open(url, '_system', 'location=yes');
    return false;
  }

  // ###########################################################################################
  // ###########################################################################################
  // HOSTNAME
  public static getDomain() {
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    if (hostname === "localhost") {
      const port = window.location.port;
      return `${protocol}//localhost:${port}`;
    }
    return `${protocol}//${hostname}`;
  }

  public static isLocalhost() {
    const hostname = window.location.hostname;
    return (hostname === "localhost") ? true : false;
  }

  // ###########################################################################################
  // ###########################################################################################
  // IP ADDRESS
  // public static getIPAddress() {
  //   const url = "http://api.ipify.org/?format=json";
  //   this.http.get(url).subscribe((res:any)=>{
  //     const ipAddress = res.ip;
  //     console.log(ipAddress);
  //     return ipAddress;
  //   });
  // }

// MISC
/*
 ██████   ██████ █████  █████████    █████████ 
░░██████ ██████ ░░███  ███░░░░░███  ███░░░░░███
 ░███░█████░███  ░███ ░███    ░░░  ███     ░░░ 
 ░███░░███ ░███  ░███ ░░█████████ ░███         
 ░███ ░░░  ░███  ░███  ░░░░░░░░███░███         
 ░███      ░███  ░███  ███    ░███░░███     ███
 █████     █████ █████░░█████████  ░░█████████ 
░░░░░     ░░░░░ ░░░░░  ░░░░░░░░░    ░░░░░░░░░  
*/

  // DEBUG
  public static debug(obj) {
    console.log(obj);
  }

  public static log(message: string, style, value = null) {
    console.log('%c' + message, style, value);
  }

  public static getObjectWithProperties(obj: any, properties: string[]) {
    let newObj: any = {};
    properties.forEach(key => {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = obj[key];
      } else {
        console.log('Object property not found', key)
      }
    });
    return newObj;
  }

  // SANITIZE PROGRAM
  public static getSanitizedProgram(program) {
    const properties = [
      "name",
      "id",
      "price",
      "pricePlan",
      "privacyOption",
      "schedule",
      "level",
      "summary",
      "description",
      "image",
    ]
    const p = HelpersService.getObjectWithProperties(program, properties);
    return p;
  }

  // GET SANITIZED USER (SANITIZE + ATTACH ACADEMY CALLING CARD)
  public static getSanitizedUser(user) {
    let u = HelpersService.sanitizeUser(user);

    if (!user.activeAcademy?.id && !user.activeAcademyId) {
      console.log('%c ERROR user active academy id NOT FOUND', defaults.styles.error, user);
    }

    // Attach active academy
    if (user.activeAcademy) {
      u.activeAcademy = {
        id: user.activeAcademy.id ? user.activeAcademy.id : user.activeAcademyId,
        siteName: user.activeAcademy.name ? user.activeAcademy.name : '',
        slug: user.activeAcademy.slug ?? HelpersService.slugify(user.activeAcademy.name),
      };
    }
    return u;
  }

  // SANITIZE USER
  public static sanitizeUser(user) {
    let u: any;
    u = {
      uid: user.uid,
      name: this.getUserName(user, user.uid),
      firstName: user.firstName,
      lastName: user.lastName,
      // screenname: user.screenname ?? null,
      avatar: user.avatar,
      // activeAcademyId: user.activeAcademyId ?? null,
      // activeProgramId: user.activeProgramId ?? null,
      activeAcademy: null,
      // academyName: user.activeAcademy.name ?? '',
    };
    if (user.screenname) u.screenname = user.screenname;
    if (user.activeAcademyId) u.activeAcademyId = user.activeAcademyId;
    if (user.activeProgramId) u.activeProgramId = user.activeProgramId;
    if (user.activeAcademy?.name) u.academyName = user.activeAcademy.name;
    if (user.timezoneOffset) u.timezoneOffset = user.timezoneOffset;
    return this.filterUndefined(u);
  }

  // USER NAME
  public static getUserName(user: any, userAuth?: any, emptyPlaceholder?: string) {
    if (user?.nickname) return user.nickname;
    if (user?.firstName && user?.lastName) return user.firstName + " " + user.lastName;
    if (user?.firstName) return user.firstName;
    if (user?.lastName) return user.lastName;
    if (user?.name) return user.name; // OLD
    if (user?.id) return HelpersService.trim(user.id);
    if (user?.uid) return HelpersService.trim(user.uid);
    if (userAuth?.email) return userAuth.email;
    if (userAuth && (typeof userAuth === 'string' || userAuth instanceof String)) return HelpersService.trim(userAuth.toString());
    if (emptyPlaceholder) return emptyPlaceholder;
    // console.log("%c Failed to resolve user name. Returning [[uid]] placeholder.", defaults.styles.warn, user);
    return "[[unknown user]]";
  }

  // DATA TABLE
  public static getTableManifest(array) {
    const uniqueKeys = HelpersService.getUniqueKeys(array);
    const tableManifest = [];
    uniqueKeys.forEach(key => {
      let dataColumn: any;
      dataColumn = {
        label: key,
        valueProperty: key,
      }
      if (key === "user") dataColumn.type = "user";
      if (key === "completedAt" || key === "createdAt") dataColumn.type = "date";
      if (key === "youTubeVideoId") dataColumn.type = "youTubeVideoId";
      tableManifest.push(dataColumn);
    });
    console.log("Table manifest generated", tableManifest);
    return tableManifest;
  }

  // ###########################################################################################
  // ###########################################################################################
  // IONIC / FIREBASE
  public static getPlatformName(includeSeparator = true) {
    return (includeSeparator ? ' - ' : '') + defaults.platform.name;
  }
  public static cleanUp(subscriptions) {
    console.log('%cClean up subscriptions', defaults.styles.component);
    if (subscriptions) { 
      subscriptions.forEach(subscription => {
        console.log('%cUnsubscribe from subscription', defaults.styles.destroyed);
        subscription.unsubscribe();
      });
    } else {
      console.log('%cCleanup expected to find subscriptions to unsubscribe.', defaults.styles.error);
    }
  }


// DEVELOPER
/*
 ██████████   ██████████ █████   █████ ██████████ █████          ███████    ███████████  ██████████ ███████████  
░░███░░░░███ ░░███░░░░░█░░███   ░░███ ░░███░░░░░█░░███         ███░░░░░███ ░░███░░░░░███░░███░░░░░█░░███░░░░░███ 
 ░███   ░░███ ░███  █ ░  ░███    ░███  ░███  █ ░  ░███        ███     ░░███ ░███    ░███ ░███  █ ░  ░███    ░███ 
 ░███    ░███ ░██████    ░███    ░███  ░██████    ░███       ░███      ░███ ░██████████  ░██████    ░██████████  
 ░███    ░███ ░███░░█    ░░███   ███   ░███░░█    ░███       ░███      ░███ ░███░░░░░░   ░███░░█    ░███░░░░░███ 
 ░███    ███  ░███ ░   █  ░░░█████░    ░███ ░   █ ░███      █░░███     ███  ░███         ░███ ░   █ ░███    ░███ 
 ██████████   ██████████    ░░███      ██████████ ███████████ ░░░███████░   █████        ██████████ █████   █████
░░░░░░░░░░   ░░░░░░░░░░      ░░░      ░░░░░░░░░░ ░░░░░░░░░░░    ░░░░░░░    ░░░░░        ░░░░░░░░░░ ░░░░░   ░░░░░ 
*/

  private static names = [
    // collection from google searches
    "Temp O'Rary",
    "Testy McTester",
    "Tess Tickles",
    "Ima Fakeperson",
    "Nota Realperson",
    "Duke Nukem",
    "Buck Naked",
    "Duck Dodgers",
    "Nota Tester",
    "Only Atester",
    "Lego Master",
    "Art Vandelay",
    "Star Wars",
    "Star Trek",
    "Stairway Toheaven",
    "Joe Bloggins",
    "Chester Tester",
    "Ineta Minute",
    "Vegan Meal",
    "Remember Me",
    "Tick Tock",
    "Howard Mushroom",
    "Desperate Now",
    "Second Attempt",
    "Hopethis Works",
    "Thumb Drive ",
    "User Spare",
    // Chat GPT generated
    "Testy McTestface",
    "Mocking Bird",
    "Beta Max",
    "Faux Pas",
    "Sim U. Lator",
    "Debug Dan",
    "Place Holder",
    "Dummy Variable",
    "Testy McTesterson",
    "Sample Size",
    "Code Monkey",
    "Glitchy McGlitchface",
    "Function Failsalot",
    "Trial N. Error",
    "Placeholder Pete",
    "Random User",
    "Testy McBeta",
    "Loop DeLoop",
    "Null Pointer",
    "Sim Ulation"
  ];

  public static getTestUserName() {
    const name = HelpersService.getRandomRecord(this.names);
    return name;
  }

  public static generateTestMember(user: any = {}, credentials?: boolean) {
    const name = HelpersService.getTestUserName().split(" ");
    if (!user?.firstName) user.firstName = name[0];
    if (!user?.lastName) user.lastName = name[1];
    if (!user?.email) user.email = "testing_" + HelpersService.getRandomInt(0,999999999) + "@test.com";
    if (user?.cohort?.length < 1) user.cohort = "Test";
    user.testMember = true; // TODO: Not implemented yet into the cloud functions, currently cohort = "Test" is used to identify test members

    // credentials
    if (credentials) {
      if (!user.password) user.password = "Temp123";
      if (!user.agreeToTerms) user.agreeToTerms = true;
      if (!user.credentials) user.credentials = true;
    }

    console.log('%c Test member generated: ' + name, defaults.styles.fresh, user);
    return user;
  }

  // LINKS TO FIRESTORE
  // private static firebaseConsoleDomain = `https://console.firebase.google.com/u/0/project/${project}/firestore/data/`;
  private static get firebaseConsoleDomain() {
    const project = defaults.platform.firebaseProject; //"hackyourhuman";
    return `https://console.firebase.google.com/u/0/project/${project}/firestore/data/`;
  }
  private static firebaseUrlSeparator = "~2F";
  private static urlWithSeparator = this.firebaseConsoleDomain + this.firebaseUrlSeparator;

  public static getLinkToFirebaseUserAcademyProgram(userId, academyId, programId) {
    return this.firebaseConsoleDomain + this.firebaseUrlSeparator + `users/${userId}/academy/${academyId}/program/${programId}`.replace("/", this.firebaseUrlSeparator);
  }
  public static getLinkToFirebaseUserAcademyProgramHabits(userId, academyId, programId, habitId?) {
    const separator = this.firebaseUrlSeparator;
      let url = this.urlWithSeparator + `users/${userId}/academy/${academyId}/program/${programId}/habits`.replace("/", this.firebaseUrlSeparator);
      if (habitId) url  = url + separator + habitId;
      return url;
  }
  public static getLinkToFirebaseUserAcademyHabits(userId, academyId, habitId?) {
    const separator = this.firebaseUrlSeparator;
    let url = this.urlWithSeparator + `users/${userId}/academy/${academyId}/habits`.replace("/", this.firebaseUrlSeparator);
    if (habitId) url  = url + separator + "habit" + separator + habitId;
    return url;
  }
  public static getLinkToFirebaseDays(userId, day, collection = "users") {
    return this.urlWithSeparator + `${collection}/${userId}/days/${day}`.replace("/", this.firebaseUrlSeparator);
  }
  public static getLinkToFirebaseUserHabits(userId) {
    return this.urlWithSeparator + `users/${userId}/habits`.replace("/", this.firebaseUrlSeparator);
  }

  public static getLinkToAcademyAllStats(academyId) {
    return this.urlWithSeparator + `academy/${academyId}/years/_ALL`.replace("/", this.firebaseUrlSeparator);
  }

  public static getLinkToUserAllStats(userId) {
    return this.urlWithSeparator + `users/${userId}/years/_ALL`.replace("/", this.firebaseUrlSeparator);
  }

  public static getLinkToFirebaseAcademyMemberDayStats(userId, academyId) {
    const separator = this.firebaseUrlSeparator;
    return this.urlWithSeparator + `academy/${academyId}/members/${userId}/days`.replace("/", this.firebaseUrlSeparator);
  }

  public static handleError(error: any, collection: string, documentId: string) {
    console.error(`%c Error accessing collection ${collection}, document ${documentId}:`, defaults.styles.error, error);
    // You can add more detailed logging or error handling here
  }
}
